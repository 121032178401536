import axios from "axios";
import ProfileNav from "../ProfileNav"
import PageName from "../model/pageName";
import { useState, useEffect, useCallback } from "react";
import { access_id , access_token , bottoken , bot_id } from "../../../Auth/localStorageVariable";
export default function Domain() {
function deletethisitem(e) {
  e.preventDefault();
  const confirmed = window.confirm('Are you sure you want to delete this item?');
  if (confirmed) {
    const id = e.target.dataset.id;
    console.log(id);
    e.target.parentElement.parentElement.remove();
  }
}
  const [traineddomain, setdomain] = useState([]);
  // eslint-disable-next-line
 const getdomain = useCallback(() => {
    axios.post(process.env.REACT_APP_API_URL+`getdomain`,
            {
               token : bottoken,  access_id,  bot : bot_id
            },
            {
                headers:   { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access_token },
            }
        )
    .then(function (response) {
      setdomain(response.data.domains);
      console.log(response.data);
    })
    .catch(function (error) {
      try {
        if (error.response.data.error !== undefined && error.response.data.error === 'Unauthorized') {
          localStorage.clear();
          window.location.href = '/';
        }
      }
      catch (e) {
        console.log('Something Went Wrong')
      }
    });
 } , [] )
  useEffect(() => {
    getdomain();
  } , [getdomain]);
  
  const [domain, setdomains] = useState('');
  const adddomain = (e) => {
    e.preventDefault();
        axios.post(process.env.REACT_APP_API_URL+`adddomain`,
            {
                domain , token : bottoken,  access_id,  bot : bot_id
            },
            {
                headers:   { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access_token },
            }
        )
          .then((response) => {
            if (response.data.status === 'success') {
              setdomains('');
              getdomain();
            }
      })
      .catch((error) => {
        console.log(error);
      });

  }

  
  return (
      <>   
        <div className="bg-gray-100">
          <ProfileNav />
            <div className="bg-gray-100 main-content position-relative vh-100 ">
              <PageName
                  name='Domain'
              />
              <div className=" card shadow-none mx-3 bg-transparent ">
                <div className="row">
                  <div className="col-12">
                    <div className="card p-4">
                      <form onSubmit={adddomain}>
                      <label htmlFor="validationCustomUsername">Domain</label>
                          <input type="text" value={domain} onChange={(e) => setdomains(e.target.value)} className="form-control" id="validationCustomUsername" placeholder="Domain  eg : agent" aria-describedby="inputGroupPrepend" required />
                            <button type="submit" className="btn btn-info mt-3">Add</button>
                      </form>
                      <div className="table-responsive p-3">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="p-2">Name</th>
                              <th className="p-2">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                          {
                            traineddomain.map((trained) => {
                                return (
                                  <tr key={`trainedid_`+trained.id}>
                                    <td className="p-2">{trained.name}</td>
                                    <td className="p-2">
                                      <i className="cursor-pointer fa-duotone fa-trash text-danger" data-id={trained.id} onClick={deletethisitem}> </i>
                                    </td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </>
  );
}
