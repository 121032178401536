export default function PageName(name) {
    function opennav() {
        var element = document.getElementById("sidenav-main");
        element.classList.toggle("d-none");
    }
    return (
        <>
            <div className="py-4 card shadow-none mx-3 bg-transparent ">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <i onClick={opennav} className="d-xl-none menu-bar fa-solid fa-bars" data-bs-toggle="tooltip" data-bs-placement="right" title="Collapse"></i> {name.name}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}