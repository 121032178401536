import React, { useState } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { Link } from 'react-router-dom';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

    const loginWithEmail = (e) => {
        e.preventDefault();
    axios
      .post(process.env.REACT_APP_API_URL+`loginuser`, {
        email,
        pass: password,
      })
        .then(function (response) {
            console.log(response);
            if (response.data.status === 'password_incorrect') {
                document.getElementById('message_signup').innerHTML = 'Password incorrect';
            }
            else if (response.data.status === 'email_not_found') {
                document.getElementById('message_signup').innerHTML = 'Email not found';
            }
            else if (response.data.status === 'login_verified') {
                localStorage.setItem('access_token', response.data.acess_token);
                localStorage.setItem('access_name', response.data.access_name);
                localStorage.setItem('access_picture', response.data.access_picture);
                localStorage.setItem('access_id', response.data.user_id);
                localStorage.setItem('access_email', response.data.access_email);
                window.location.href = '/Profile';
            }
        
        })
      .catch(function (error) {
        console.log(error);
      });
  };

  const decodeJwtResponse = (token) => {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) { return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);}).join('')
    );
    return JSON.parse(jsonPayload);
  };

  const handleCredentialResponse = (response) => {
    var responsePayload = decodeJwtResponse(response.credential);
    var user_id = responsePayload.sub;
    var access_email = responsePayload.email;
    var access_picture = responsePayload.picture;
    var access_name = responsePayload.name;
      axios.post(process.env.REACT_APP_API_URL+'login-user',
          {
          get_user: 'google',
          user_id,
          access_email,
          access_picture,
          access_name,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        localStorage.setItem('access_token', res.data.acess_token );
        localStorage.setItem('access_name', access_name);
        localStorage.setItem('access_picture', access_picture);
        localStorage.setItem('access_id', user_id);
        localStorage.setItem('access_email', access_email);
        window.location.href = '/Profile';
      })
      .catch((err) => {
        console.error(err);
      });
  };
    return (
        <>
    <div className="container">
        <div className="row">
            <div className="col-sm-3 col-6 mx-auto">
                <div className="modal fade loginmodal" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="card-header text-center pb-1">
                                    <div className="d-flex align-items-end justify-content-end">
                                        <div className='w-100'>
                                            <h4 className="font-weight-bolder mb-1">Log in</h4>
                                            <p className="mb-0" id="message_signup">Welcome back</p>
                                        </div>
                                        <h6 className='px-3 py-2 cursor-pointer' data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-xmark"></i></h6>
                                    </div>                 
                                </div>
                                <div className="card-body pb-0">
                                    <form onSubmit={loginWithEmail}>
                                        <div className="mb-3">
                                            <input type="email" className="form-control" id="login_email" placeholder="Email" aria-label="Email"   value={email} onChange={(e) => setEmail(e.target.value)} />
                                        </div>
                                        <div className="mb-3">
                                            <input type="password" className="form-control" placeholder="Password" id="login_pass" aria-label="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                        </div>
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="rememberMe" />
                                            <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
                                        </div>
                                        <div className="text-center">
                                            <button id="loginuser" type="submit" className="btn bg-gradient-info w-100 mt-4 mb-2">Sign in</button>
                                        </div>
                                    </form>
                                </div>
                                <div className="card-footer text-center pt-0 px-sm-4 px-1">
                                    <div className="row px-xl-5 px-sm-4 px-3">
                                        <div className="mt-3 mb-4 position-relative text-center">
                                            <p className="text-sm font-weight-bold text-secondary text-border p-0 d-inline z-index-2 bg-white px-3">
                                                or continue with
                                            </p>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-center'>
                                        <GoogleOAuthProvider clientId="467291088215-ulvpckdk6ipfhf3inci8dqvh11n2s9c6.apps.googleusercontent.com">
                                            <GoogleLogin
                                                    onSuccess={credentialResponse => {
                                                            handleCredentialResponse(credentialResponse)
                                                    }}
                                                    onError={() => {
                                                        console.log('Login Failed');
                                                        }}
                                                />             
                                        </GoogleOAuthProvider>            
                                        </div>
                                    </div>
                                    <p className="mb-0 mt-3 text-sm mx-auto">
                                        Don't have an account? <Link to="/Signup" className="text-info text-gradient font-weight-bold">Sign up</Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        </>
    )
}
