import Nav from "../model/homepage/Nav";
import Blog from "../model/homepage/Blog";
import Footer from "../model/homepage/Footer";
import PageArea from "../model/homepage/PageArea";
export default function Blogs() {
       const data = [
        {
            id: 1,
            code : 'creative-design',
            title: "Creative design clients response is better",
            date: "20 july, 2019",
            comments: 13,
            image: "assets/services/s2.png",
            content: "Dummy text is also used to demonstrate the appearance of different typefaces and layouts, and in general the content of dummy text is nonsensical."
        },
        {
            id: 2,
            code : 'creative-design',
            title: "Creative design clients response is better",
            date: "20 july, 2019",
            comments: 13,
            image: "assets/services/s1.png",
            content: "Dummy text is also used to demonstrate the appearance of different typefaces and layouts, and in general the content of dummy text is nonsensical."
        },
        {
            id: 3,
            code : 'creative-design',
            title: "Creative design clients response is better",
            date: "20 july, 2019",
            comments: 13,
            image: "assets/services/s2.png",
            content: "Dummy text is also used to demonstrate the appearance of different typefaces and layouts, and in general the content of dummy text is nonsensical."
           },
         {
            id: 4,
            code : 'creative-design',
            title: "Creative design clients response is better",
            date: "20 july, 2019",
            comments: 13,
            image: "assets/services/s2.png",
            content: "Dummy text is also used to demonstrate the appearance of different typefaces and layouts, and in general the content of dummy text is nonsensical."
           },
          {
            id: 5,
            code : 'creative-design',
            title: "Creative design clients response is better",
            date: "20 july, 2019",
            comments: 13,
            image: "assets/services/s2.png",
            content: "Dummy text is also used to demonstrate the appearance of different typefaces and layouts, and in general the content of dummy text is nonsensical."
           },
           {
            id: 6,
            code : 'creative-design',
            title: "Creative design clients response is better",
            date: "20 july, 2019",
            comments: 13,
            image: "assets/services/s2.png",
            content: "Dummy text is also used to demonstrate the appearance of different typefaces and layouts, and in general the content of dummy text is nonsensical."
           },
            {
            id: 7,
            code : 'creative-design',
            title: "Creative design clients response is better",
            date: "20 july, 2019",
            comments: 13,
            image: "assets/services/s2.png",
            content: "Dummy text is also used to demonstrate the appearance of different typefaces and layouts, and in general the content of dummy text is nonsensical."
        },
    ]

    return (
        <>
            <Nav
                theme = "white"
            />
            <PageArea
                title="Blog"
            />
            <div className="blog-area fix area-padding">
                <div className="container">
                    <div className="row">
                        <div className="blog-grid">
                                {
                                    data.map((item) => (
                                    <Blog
                                        key={item.id}
                                        code={item.code}
                                        title={item.title}  
                                        date={item.date}
                                        comments={item.comments}
                                        image={item.image}
                                        content={item.content}
                                        link="/BlogPreview"
                                    />
                                ))}
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}