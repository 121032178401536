import ProfileNav from "../ProfileNav"
import PageName from "../model/pageName";



export default function Implementation() {
    
  return (
      <>   
           <div className="bg-gray-100">
          <ProfileNav />
            <div className="bg-gray-100 main-content position-relative vh-100 ">
              <PageName
                  name='Implementation'
              />
              <div className=" card shadow-none mx-3 bg-transparent ">
                <div className="row">
                  <div className="col-12">
                    <div className="card p-4">
                        <h6>Import Socket Library</h6>
                        <pre className="bg-dark text-white">
                            &lt;script src="https://bots.bimash.com.np/plugin/socket.js"&gt;&lt;/script&gt;
                        </pre>
                        <h6>Import Bot Library</h6>
                        <pre className="bg-dark text-white">
                            &lt;script&gt; src="https://bots.bimash.com.np/plugin/bot.js"&gt;&lt;/script&gt;
                        </pre>
                        <h6>Bot Initialize</h6>
                        <pre className="bg-dark text-white">
                            &lt;script&gt;
                                <br/>
                                &#160;&#160;Bot.init &#40;&#123;
                                <br/>
                                &#160;&#160;&#160;&#160;name: 'Track',
                                <br/>
                                &#160;&#160;&#160;&#160;id: 'track_ntZWX',
                                <br/>
                                &#160;&#160;&#160;&#160;token: '9c2e1b8f-9a96-ae19-265c-9ee11ae7c0fd',
                                <br/>
                                &#160;&#160;&#160;&#160;theme: 'light',
                                <br/>
                                &#160;&#160;&#125;&#41;;
                                <br/>
                            &lt;script&gt;
                        </pre>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </>
  );
}
