import Blog from "./Blog"
export default function News() {
    const data = [
        {
            id: 1,
            code : 'creative-design',
            title: "Creative design clients response is better",
            date: "20 july, 2019",
            comments: 13,
            image: "assets/services/s2.png",
            content: "Dummy text is also used to demonstrate the appearance of different typefaces and layouts, and in general the content of dummy text is nonsensical."
        },
        {
            id: 2,
            code : 'creative-design',
            title: "Creative design clients response is better",
            date: "20 july, 2019",
            comments: 13,
            image: "assets/services/s2.png",
            content: "Dummy text is also used to demonstrate the appearance of different typefaces and layouts, and in general the content of dummy text is nonsensical."
        },
        {
            id: 3,
           code : 'creative-design',
            title: "Creative design clients response is better",
            date: "20 july, 2019",
            comments: 13,
            image: "assets/services/s2.png",
            content: "Dummy text is also used to demonstrate the appearance of different typefaces and layouts, and in general the content of dummy text is nonsensical."
        },
    ]
    return (
        <>
            <div className="blog-area fix area-padding">
            <div className="container">
                <div className="row">
    				<div className="col-md-12 col-sm-12 col-xs-12 wow fadeInUp" data-wow-delay="0.4s" >
    					<div className="section-headline text-center">
    						<h3>artificial latest news</h3>
    					</div>
    				</div>
    			</div>
                <div className="blog-grid row">
                        {
                            data.map((item) => (
                            <Blog
                                key={item.id}
                                title={item.title}  
                                code = {item.code}
                                date={item.date}
                                comments={item.comments}
                                image={item.image}
                                content={item.content}
                            />
                        ))}
                </div>
            </div>
        </div>
        </>
    )
}