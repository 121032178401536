import WebTrafficBan from "./WebTrafficBan";
import WebTrafficReq from "./WebTrafficReq";
import WebTrafficUniq from "./WebTrafficUniq";

export default function WebTraffic({data}){
    return(<>
        <section>
            <div className="container">
                <div className="row justify-space-between py-2">
                    <div className="col ms-auto">
                        <div className="nav-wrapper position-relative end-0">
                            <ul className="nav nav-pills nav-fill p-1" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <li className="nav-item">
                                    <a className="nav-link active" id="v-pills-requests-tab" data-bs-toggle="pill" href="#v-pills-requests" role="tab" aria-controls="v-pills-requests" aria-selected="true">Requests</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="v-pills-bandwidth-tab" data-bs-toggle="pill" href="#v-pills-bandwidth" role="tab" aria-controls="v-pills-bandwidth" aria-selected="false">Bandwidth</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="v-pills-uniqvisit-tab" data-bs-toggle="pill" href="#v-pills-uniqvisit" role="tab" aria-controls="v-pills-uniqvisit" aria-selected="false">Unique Visitors</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 me-auto my-auto text-start">
                            <div className="tab-content p-0" id="v-pills-tabContent">
                            <div className="tab-pane fade show active" id="v-pills-requests" role="tabpanel" aria-labelledby="v-pills-requests-tab">
                                <WebTrafficReq
                                    data={data}
                                />
                            </div>
                            <div className="tab-pane fade" id="v-pills-bandwidth" role="tabpanel" aria-labelledby="v-pills-bandwidth-tab">
                                <WebTrafficBan
                                    data={data}
                                />
                            </div>
                            <div className="tab-pane fade" id="v-pills-uniqvisit" role="tabpanel" aria-labelledby="v-pills-uniqvisit-tab">
                                <WebTrafficUniq
                                    data={data}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>)
}