import { Link } from "react-router-dom"
export default function Blog(data) {
    return (
        <>
            <div className="col-md-4 col-sm-12 col-xs-12">
                <div className="single-blog wow fadeInUp" data-wow-delay="0.3s">
                    <div className="blog-image">
                        <Link className="image-scale" to={`/Blog/`+data.code}>
                            <img className="blog-img-preview" src={data.image} alt=""/>
                        </Link>
                    </div>
                    <div className="blog-content">
                        <Link to={`/Blog/`+data.code}>
                            <h4>{data.title}</h4>
                        </Link>
                        <div className="blog-meta">
                            <span className="date-type">
                                {data.date}
                            </span>
                            <span className="comments-type">
                                <i className="fa fa-comment-o"></i>
                                {data.comments}
                            </span>
                        </div>
                        <p>{data.content}</p>
                        <Link className="blog-btn" to={`/Blog/`+data.code}> Read more</Link>
                    </div>
                </div>
            </div>
        </>
    )
}