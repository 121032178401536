import * as echarts from 'echarts';
import { useEffect, useState } from 'react';

export default function OverViewLine({id , data }){
    const [xdata , setXdata] = useState([]);
    const [ydata , setYdata] = useState([]);
    const [dataminmax , setDataminmax] = useState({
        min : 0,
        max : 0
    });
    const [totalreq , setTotalreq] = useState(0);
    const [reqname , setReqname] = useState('');
   

    useEffect(() => {
        if(id === 'uniq_visitors_ovl'){
            if(data.length > 0){
                var total = 0;
                var newxdata = [];
                var newydata = [];
                data.map((item)=>{
                    newxdata.push(item.date.date);
                    newydata.push(item.uniq.uniques);
                    total += item.uniq.uniques;
                }
                )
                setXdata(newxdata);
                setYdata(newydata);
                if(total > 1000){
                    total = (total/1000).toFixed(2) + 'K';
                }
                // get min and max value from newxdata
                var min = Math.min(...newydata);
                var max = Math.max(...newydata);
                setDataminmax({
                    min : min,
                    max : max
                })

                setTotalreq(total);
            }
            setReqname('Unique Visitors')
        }
        else if(id === 'requests_ovl'){
            if(data.length > 0){
                var total = 0;
                var newxdata = [];
                var newydata = [];
                data.map((item)=>{
                    newxdata.push(item.date.date);
                    newydata.push(item.sum.requests);
                    total += item.sum.requests;
                    }
                )
                setXdata(newxdata);
                setYdata(newydata);
                total = (total/1000).toFixed(2) + 'K';
                var min = Math.min(...newydata);
                var max = Math.max(...newydata);
                setDataminmax({
                    min : min,
                    max : max
                })
                setTotalreq(total);
            }
            setReqname('Requests')
        }
        else if(id === 'bytes_ovl'){
            if(data.length > 0){
                var total = 0;
                var newxdata = [];
                var newydata = [];
                data.map((item)=>{
                    newxdata.push(item.date.date);
                    newydata.push(item.sum.bytes);
                    total += item.sum.bytes;
                    }
                )
                setXdata(newxdata);
                setYdata(newydata);
                total = (total/1000000000).toFixed(2) + 'GB';
                setTotalreq(total);
                var min = Math.min(...newydata);
                var max = Math.max(...newydata);
                setDataminmax({
                    min : min,
                    max : max
                })
            }
            setReqname('Bandwidth')
        }
        else if(id === 'cachedRequests_ovl'){
            if(data.length > 0){
                var total = 0;
                var newxdata = [];
                var newydata = [];
                data.map((item)=>{
                    newxdata.push(item.date.date);
                    newydata.push(item.sum.cachedBytes);
                    total += item.sum.cachedBytes;
                    }
                )
                setXdata(newxdata);
                setYdata(newydata);
                total = (total/1000000).toFixed(2) + 'MB';
                setTotalreq(total);
                var min = Math.min(...newydata);
                var max = Math.max(...newydata);
                setDataminmax({
                    min : min,
                    max : max
                })
            }
            setReqname('Data Cached')
        }
    }, [data])
    function initOverLinechart(id){
        var chartDom = document.getElementById(id);
        var myChart = echarts.getInstanceByDom(chartDom);
        if (myChart === undefined) {
            myChart = echarts.init(chartDom);
        }
        var option;

        option = {
        visualMap: [
            {
                show: false,
                type: 'continuous',
                seriesIndex: 0,
                min: dataminmax.min,
                max: dataminmax.max
            }
            ],
        gradientColor: ['#00c6ff', '#F0F8FF'],
        xAxis: {
            show : false,
            type: 'category',
            boundaryGap: false,
            data: xdata
        },
        yAxis: {
            show : false,
            type: 'value'
        },
        stateAnimation : {
            duration : 3000,
            easing : 'cubicOut',
        },
        
        tooltip : {
            trigger: 'axis',
            formatter: '{b} <br/> {c}'
        },
        series: [
            {
            data: ydata,
            type: 'line',
            areaStyle: {},
                label : {
                    position : 'top'
                }
            }
        ]
        };

        option && myChart.setOption(option);
    }
   useEffect(() => {
        if( document.getElementById(id)){
            initOverLinechart(id);
        }
    }, [id , xdata , ydata])
    return <>
        <div className='d-flex align-items-center justify-content-between' style={{height : '100px'}} >
            <div className=' p-3'>
                <h6 className='m-0'>{reqname}</h6>
                <h5 className='m-0'>{totalreq}</h5>
            </div>
            <div id={id} style={{width: '80%', height: '200px'}}>
            </div>
        </div>
    </>


}