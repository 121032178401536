import * as echarts from 'echarts';
import { useEffect, useState } from 'react';

export default function WebTrafficBan({ data }){
    const id = 'requests_ovl_banwid';
    const [ydatacached , setYdataChached] = useState([]);
    const [ydatauncached , setYdataunChached] = useState([]);
    
    const [xdata , setXdata] = useState([]);
    const [ydata , setYdata] = useState([]);
    const [dataminmax , setDataminmax] = useState({
        min : 0,
        max : 0
    });
    const [totalreq , setTotalreq] = useState(0);
    const [chached , setChached] = useState(0);
    const [uncached , setUncached] = useState(0);
    function convertSize(size){
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (size === 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(size) / Math.log(1024)));
        return (size / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
     }
    useEffect(() => {
        if(data.length > 0){
            var total = 0;
            var cachedreq = 0;
            var newxdata = [];
            var newydata = [];
            var newydatacached = [];
            var newydatauncached = [];
            data.map((item)=>{
                newxdata.push(item.date.date);
                newydata.push(item.sum.bytes);
                newydatacached.push(item.sum.cachedBytes);
                newydatauncached.push(item.sum.bytes - item.sum.cachedBytes);
                total += item.sum.bytes;
                cachedreq += item.sum.cachedBytes;

            }
            )
            
            setUncached(convertSize(total - cachedreq));
            setXdata(newxdata);
            setYdata(newydata);
            var min = Math.min(...newydata);
            var max = Math.max(...newydata);
            setDataminmax({
                min : min,
                max : max
            })
            setChached(convertSize(cachedreq));
            setYdataChached(newydatacached);
            setYdataunChached(newydatauncached);
            setTotalreq(convertSize(total));
        }
    }, [data])
    function initOverLinechart(id){
        var chartDom = document.getElementById(id);
        var myChart = echarts.getInstanceByDom(chartDom);
        if (myChart === undefined) {
            myChart = echarts.init(chartDom);
        }
        var option;

        option = {
        visualMap: [
            {
                show: false,
                type: 'continuous',
                seriesIndex: 0,
                min: dataminmax.min,
                max: dataminmax.max
            }
            ],
        gradientColor: ['#ff3e1d', '#F0F8FF'],
        xAxis: {
            show : true,
            type: 'category',
            boundaryGap: false,
            data: xdata
        },
        yAxis: {
            show : true,
            type: 'value',
            axisLabel: {
                formatter: function (value) {
                    // Convert value to GB and format it
                    const formattedValue = convertSize(value)
                    return formattedValue;
                 }
            }
        
        },
        stateAnimation : {
            duration : 3000,
            easing : 'cubicOut',
        },
        
        tooltip : {
            trigger: 'axis',
            formatter: function(params) {
                var tip = params[0].name + '<br/>';
                params.forEach(function(param) {
                    tip += param.marker + param.seriesName + ': ' + convertSize(param.value) + '<br/>';
                });
                return tip;
            }
        },
        series: [
            {
              name: 'Total Requests',
              type: 'line',
              areaStyle: {},
              emphasis: {
                focus: 'series'
              },
              data: ydata
            },
            {
              name: 'Cached Requests',
              type: 'line',
              areaStyle: {},
              emphasis: {
                focus: 'series'
              },
              data : ydatacached
            },
            {
                name: 'unCached Requests',
                type: 'line',
                areaStyle: {},
                emphasis: {
                  focus: 'series'
                },
                data : ydatauncached
              }
          ]
        };

        option && myChart.setOption(option);
    }
   useEffect(() => {
        var width = document.getElementById('v-pills-requests').offsetWidth;
        document.getElementById(id).style.width = width + 'px';
        if( document.getElementById(id)){
            initOverLinechart(id);
        }
    }, [id , xdata , ydata])
    return <>
        <div style={{
            width: '100%',
        }}>
            <hr />
            <div className="px-5 w-100 d-flex justify-content-between">
                <div className=' p-3'>
                    <h6 className='m-0'>Total Reqests</h6>
                    <h5 className='m-0'>{totalreq}</h5>
                </div>
                <div className=' p-3'>
                    <h6 className='m-0'>Cached Reqests</h6>
                    <h5 className='m-0'>{chached}</h5>
                </div>
                <div className=' p-3'>
                    <h6 className='m-0'>Uncached Reqests</h6>
                    <h5 className='m-0'>{uncached}</h5>
                </div>
            </div>
            <div id={id} style={{ width:'100%' , height: '400px'}}>
            </div>
        </div>
    </>


}