import { Link } from "react-router-dom";
import DeepAnalytic from "./charts/DeepAnalytic";
import ProfileNav from "../../ProfileNav";
import PageName from "../../model/pageName";

export default function Analytic() {

return <>
       <div className="bg-gray-100">
          <ProfileNav />
            <div className="bg-gray-100 main-content position-relative vh-100 ">
              <PageName
                  name='Dashboard'
              />
              <div className=" card shadow-none mx-3 bg-transparent ">
                <div className="row">
                  <DeepAnalytic />
                </div>
              </div>
          </div>
        </div>
    </>
}

