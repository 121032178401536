import CountryFlag from 'react-country-flag';
import countryList from 'country-list';
import { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
import WorldMap from './WorldMap';

export default function CountryMore({data}){
    const [countryLists , setCountryLists] = useState([]);
    const [mapdata , setMapdata] = useState({})
     const [loaded , setLoaded] = useState(false)
    const [mapminmax , setMapminmax] = useState({
        min : 0,
        max : 0
    })
    const extractTotalData = (data) => {
        const totalData = [];
        data.forEach(item => {
          const countryMap = item.sum.countryMap;
          countryMap.forEach(country => {
            const existingCountry = totalData.find(data => data.clientCountryName === country.clientCountryName);
            if (existingCountry) {
              existingCountry.bytes += country.bytes;
              existingCountry.requests += country.requests;
            } else {
              totalData.push({
                clientCountryName: country.clientCountryName,
                bytes: country.bytes,
                requests: country.requests
              });
            }
          });
        });
        const sortedCountryLists = [...totalData].sort((a, b) => b.requests - a.requests);
            var min = Math.min(...sortedCountryLists.map(item => item.requests));
            var max = Math.max(...sortedCountryLists.map(item => item.requests));
            setMapminmax({
                min : min,
                max : max
            })
        setCountryLists(sortedCountryLists);
        setLoaded(false)
    };
    const mapdatas = () => {
        if(countryLists.length > 0){
            const transformedData = countryLists.reduce((acc, item) => {
                acc[item.clientCountryName] = item.requests;
                return acc;
              }, {});
          
            setMapdata(transformedData);
            setLoaded(true);
        }
    }
    useEffect(() => {
    extractTotalData(data);
    }, [data]);
    useEffect(() => {
        mapdatas();
    }, [countryLists]);
      return(
        <>  
            <h4>Web Traffic Requests by Country</h4>
           <div  style={{height : '700px'}}>
          {(loaded) ? (
             <WorldMap 
             countries={mapdata}
             mapminmax = {mapminmax}
            />
          ) : (
                <>
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </>
          )
        }
           </div>
           <div  id='traffic_details' className='table-responsive'>
           <table className='table   w-100 mt-5'>
                <thead>
                    <tr>
                        <th className='text-uppercase text-secondary text-xxs font-weight-bolder opacity-7'>Country</th>
                        <th className='text-uppercase text-secondary text-xxs font-weight-bolder opacity-7'>Traffic</th>
                        <th className='text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-end'>Bandwidth</th>
                    </tr>
                </thead>
                <tbody >
                    {countryLists.map((item) => (
                        // if item.clientCountryName !== 'T1' print else dont
                        (item.clientCountryName !== 'T1') ? ( 
                        <tr key={item.clientCountryName}>
                            <td>
                                <CountryFlag countryCode={item.clientCountryName} svg/>
                                <span> {countryList.getName(item.clientCountryName)}</span>
                            </td>
                            <td>
                                <span>{
                                    // bytes to MB
                                    (item.bytes > 1000000) ? (item.bytes/1000000).toFixed(2) + 'MB' : (item.bytes/1000).toFixed(2) + 'KB'
                                    }</span>
                            </td>
                            <td  className='text-end'>
                                <span>{
                                    (item.requests > 1000) ? (item.requests/1000).toFixed(2) + 'K' : (item.requests)
                                    }</span>
                            </td>
                        </tr>) : 
                        <tr key={item.clientCountryName}>
                            <td>
                                <i className="fa-duotone fa-flag"></i>
                                <span> Unknown </span>
                            </td>
                            <td>
                                <span>{
                                    // bytes to MB
                                    (item.bytes > 1000000) ? (item.bytes/1000000).toFixed(2) + 'MB' : (item.bytes/1000).toFixed(2) + 'KB'
                                    }</span>
                            </td>
                            <td  className='text-end'>
                                <span>{
                                    (item.requests > 1000) ? (item.requests/1000).toFixed(2) + 'K' : (item.requests)
                                    }</span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
           </div>
            
        </>
      )
}


