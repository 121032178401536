import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from './pages/HomePage'
import Blogs from './pages/Blog';
import Profile from './pages/Profile';
import BlogPreview from './pages/BlogPreview';
import Entity from './model/profile/pages/Entity'
import Intents from './model/profile/pages/Intents'
import Domain from './model/profile/pages/Domain'
import BotDashboard from './model/profile/pages/BotDashboard'
import './App.css';
import Implementation from "./model/profile/pages/Implementation";
import Chats from "./model/profile/pages/Chats";
import ApiDocs from "./model/profile/pages/ApiDocs";
import Dashboard from "./model/profile/pages/Dashboard/Dashboard";
import Analytic from "./model/profile/pages/Dashboard/Analytic";

function App() {

 
  const routes = [
   {
    path: '/HomePage',
    component: HomePage,
    },
   {
    path: '/Blog',
    component: Blogs,
    },
    {
    path: '/Blog/:id',
    component: BlogPreview,
    },
      {
    path: '/Profile',
    component: Profile,
    },
   {
    path: '/Profile/Entity',
    component: Entity,
  },
  {
    path: '/Profile/Entity',
    component: Entity,
  },
  {
    path: '/Profile/Entity/:id',
    component: Entity,
  },
  {
    path: '/Profile/Intents',
    component: Intents,
  },
  {
    path: '/Profile/Domain',
    component: Domain,
    },
  {
  path: '/Profile/BotDashboard',
  component: Analytic,
  },
  {
    path  : '/Profile/Implement',
    component : Implementation,
  },
  {
    path  : '/Profile/Chats',
    component : Chats,
  },
  {
    path  : '/Profile/ApiDocs',
    component : ApiDocs,
  },
  
  
];
  
  return (
    <>
      <Routes>
        {routes.map((route) => (
          <Route key={route.path} path={route.path} element={<route.component />} />
        ))}
        <Route
          path="*"
          element={
            <HomePage />
          }
        />
      </Routes>  
    </>
  );
}

export default App;
