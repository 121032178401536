import ProfileNav from "../ProfileNav"
import PageName from "../model/pageName";
import TrainedData from "../model/TrainedData";
import axios from "axios";
import { useState, useEffect } from "react";
import TrainForm from "../model/TrainForm";
import Console from "../model/TrainingLog";
export default function Entity() {
    const [trainedintent, setintent] = useState([]);
    const [intentTitle, setintentTitle] = useState('agent.greetings');
    
    const [selectedDomain, setSelectedDomain] = useState('1');
    const [selectedIntent, setSelectedIntent] = useState('1');
    
    const handleSelectChange = (e) => {
        const {value } = e.target;
        setSelectedDomain(value.split('.')[0]);
        const selectedOption = e.target.options[e.target.selectedIndex];
        const optionText = selectedOption.text;
        setintentTitle(optionText);    
        setSelectedIntent(value.split('.')[1]);
    };

    useEffect(() => {
        var access_token = localStorage.getItem("access_token");
        var access_id = localStorage.getItem("access_id");
        var bottoken = localStorage.getItem("bottoken");
        var bot_id = localStorage.getItem("bot_id");
        axios.post(process.env.REACT_APP_API_URL+`getintents`,
            {
                token : bottoken,  access_id,  bot : bot_id
            },
            {
                headers:   { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access_token },
            }
        )
        .then(function (response) {
            setintent(response.data.domains);
        })
        .catch(function (error) {
            try {
                if (error.response.data.error !== undefined && error.response.data.error === 'Unauthorized') {
                localStorage.clear();
                window.location.href = '/';
            } 
            }
            catch (e) {
                console.log('Something Went Wrong')
            }
        });
    }, []);
  return (
      <>   
        <div className="bg-gray-100">
            <ProfileNav />
            <div className="bg-gray-100 main-content position-relative vh-100 ">
                <PageName
                    name='Entity'
                />
                <div className=" card shadow-none mx-3 bg-transparent ">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-body">
                                   <label htmlFor=""> Select Intents</label>
                                    <select  name=""  className="form-select mt-2"  value={`${selectedDomain}.${selectedIntent}`} onChange={handleSelectChange}>
                                        {
                                            trainedintent.map((intents) => {
                                                return intents.domain_name && intents.intent_name && (
                                                <option
                                                    key={`domain${intents.domain_id}${intents.intent_id}`}
                                                    value={`${intents.domain_id}.${intents.intent_id}`}
                                                >
                                                    {intents.domain_name}.{intents.intent_name}
                                                </option>
                                                );
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="card-body">
                                    <TrainForm name={intentTitle} domain={selectedDomain} intent={selectedIntent} />
                                </div>
                            </div>
                            <div className="card mt-3">
                                <div className="card-body">
                                      Training Log
                                      <Console
                                        name={intentTitle}
                                      />
                                </div>
                            </div>
                        </div>
                            <TrainedData name={intentTitle} domain={selectedDomain} intent={selectedIntent} />
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}
