export default function HeroPage() {
    return (
        <>
            <div className="intro-area intro-home">
                <div className="bg-wrapper">
                        <img src="./assets/background/bg.jpg" alt=""/>
                </div>
                <div className="intro-content">
                    <div className="slider-content">
                    <div className="container">
                        <div className="row">
                        <div className="col-md-6 col-sm-6 col-xs-12">
                            <div className="layer-1  wow fadeInUp" data-wow-delay="0.3s" >
                                <h6 className="best-title"><span className="slide-icons"><i className="fa-duotone fa-message-bot"></i></span> Next genaretion chatbot</h6>
                            <h2 className="title2">Train &amp; Implement </h2>
                            </div>
                            <div className="layer-2 wow fadeInUp" data-wow-delay="0.5s">
                            <p>Enchance your business with custom chatbots</p>
                            </div>
                            <div className="layer-3  wow fadeInUp" data-wow-delay="0.7s" >
                            <span className="ready-btn left-btn">Services</span>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 hidden-xs">
                            <div className="">
                                <div className="slide-top-image item-bounce">
                                    <img src="assets/slider/sp1.png" alt=""/>
                                </div>
                                <div className="slide-images">
                                    <img src="assets/slider/s1.png" alt=""/>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}