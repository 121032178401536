export default function Feature() {
    return (
        <>
        <div className="feature-area area-padding">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="feature-image item-bounce wow fadeInLeft animated" data-wow-delay="0.3s" >
                            <img src="assets/feature/ft.png" alt=""/>
                        </div>
                        <div className="triangle-1">
                            <img src="assets/about/t1.png" alt=""/>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="feature-inner wow fadeInRight" data-wow-delay="0.3s" >
                            <div className="feature-text">
                               <h3>Power Aesthetic Surgery Commendation System</h3>
                                <p>Replacing a  maintains the amount of lines. When replacing a selection. help agencies to define their new business objectives and then create the road map.</p>
                                <ul>
                                   <li className="mb-2"><i className="fa-regular fa-server"></i><span>A community with a unique mission</span></li>
                                    <li className="mb-2"><i className="fa-regular fa-brain-circuit"></i><span>AI-powered text generator is the scariest thing</span></li>
                                    <li className="mb-2"><i className="fa-regular fa-clock"></i><span>AI will automate cybersecurity improve</span></li>
                                    <li className="mb-2"><i className="fa-regular fa-messages"></i><span>Scientists invent robotic biker shorts</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
         <div className="feature-area-2 area-padding">
            <div className="container">
                <div className="row">
                   <div className="hidden-md hidden-lg hidden-sm col-xs-12">
                        <div className="feature-image item-bounce wow fadeInRight animated" data-wow-delay="0.3s" >
                            <img src="img/feature/ft1.png" alt=""/>
                        </div>
                    </div>
                   <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="feature-inner wow fadeInLeft" data-wow-delay="0.3s" >
                            <div className="feature-text">
                               <h3>Auto-tracking of Quay Crane for Lighter</h3>
                                <p>Replacing a  maintains the amount of lines. When replacing a selection. help agencies to define their new business objectives and then create the road map.</p>
                                <ul>
                                   <li className="mb-2"><i className="fa-regular fa-microchip-ai"></i><span>A community with a unique mission</span></li>
                                    <li className="mb-2"><i className="fa-light fa-clouds"></i><span>AI-powered text generator is the scariest thing</span></li>
                                    <li className="mb-2"><i className="fa-regular fa-lock"></i><span>AI will automate cybersecurity improve</span></li>
                                    <li className="mb-2"><i className="fa-regular fa-bolt"></i><span>Scientists invent robotic biker shorts</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 hidden-xs">
                        <div className="feature-image item-bounce wow fadeInRight animated" data-wow-delay="0.3s" >
                            <img src="assets/feature/ft1.png" alt=""/>
                        </div>
                        <div className="triangle-2">
                            <img src="assets/about/t2.png" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}