import { Link } from "react-router-dom"
export default function WelcomeArea() {
    return (
        <>
            <div className="welcome-area area-padding">
            <div className="container">
				<div className="all-services row">
					<div className="col-md-4 col-sm-6 col-xs-12">
						<div className="well-services wow fadeInUp" data-wow-delay="0.3s" >
							<div className="services-img">
								<img src="assets/services/w1.png" alt=""/>
							</div>
							<div className="main-wel">
								<div className="wel-content">
									<h4>Natural Language Processing</h4>
									<p>Aspernatur sit adipisci quaerat unde at neque Redug Lagre dolor sit amet consectetu. independent agency, free from the internal demands.</p>
									<Link className="wel-btn" to="#"><i className="icon icon-arrow-right"></i></Link>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-4 col-sm-6 col-xs-12">
						<div className="well-services wow fadeInUp" data-wow-delay="0.5s" >
							<div className="services-img">
								<img src="assets/services/w2.png" alt=""/>
							</div>
							<div className="main-wel">
								<div className="wel-content">
									<h4>Computer Vision &amp; Pattern Recognition</h4>
									<p>Aspernatur sit adipisci quaerat unde at neque Redug Lagre dolor sit amet consectetu. independent agency, free from the internal demands.</p>
									<Link className="wel-btn" to="#"><i className="icon icon-arrow-right"></i></Link>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-4 col-sm-6 col-xs-12">
						<div className="well-services wow fadeInUp" data-wow-delay="0.7s" >
							<div className="services-img">
								<img src="assets/services/w3.png" alt=""/>
							</div>
							<div className="main-wel">
								<div className="wel-content">
									<h4>Sequential Data Analytics</h4>
									<p>Aspernatur sit adipisci quaerat unde at neque Redug Lagre dolor sit amet consectetu. independent agency, free from the internal demands.</p>
									<Link className="wel-btn" to="#"><i className="icon icon-arrow-right"></i></Link>
								</div>
							</div>
						</div>
					</div>
					<div className="hidden-md hidden-lg col-sm-6 col-xs-12">
						<div className="well-services wow fadeInUp animated" data-wow-delay="0.9s" >
							<div className="services-img">
								<img src="assets/services/w4.png" alt=""/>
							</div>
							<div className="main-wel">
								<div className="wel-content">
									<h4>User Behavior Prediction</h4>
									<p>Aspernatur sit adipisci quaerat unde at neque Redug Lagre dolor sit amet consectetu. independent agency, free from the internal demands.</p>
									<Link className="wel-btn" to="#"><i className="icon icon-arrow-right"></i></Link>
								</div>
							</div>
						</div>
					</div>
				</div>
            </div>
            </div>
            </>
    )
}