import '../../../css/style.css'
import { useEffect } from 'react';
export default function Console({ name }) {

    function trainChanged() {
        const devices = document.getElementById('devices');
        devices.innerHTML = ' ';
        var console_ui = localStorage.getItem('console');
        if (console_ui === 'true') {
            const qur = localStorage.getItem('console_qur').split(',');
            const ans = localStorage.getItem('console_ans').split(',');
            var i = 0;
            var k = 0;
            devices.innerHTML += `<span class="output-cmd">Training Started</span>`;
            devices.innerHTML += `<span class="output-cmd">Adding Utterance</span>`;

            const qurinter = setInterval(() => {
                if(qur[i] !== null && qur[i] !== undefined) {
                    devices.innerHTML += `<span class="output-cmd">${qur[i]}</span>`;
                }
                if (i >= qur.length) {
                    clearInterval(qurinter);
                    devices.innerHTML += `<span class="output-cmd">Utterance Added</span>`;
                    startans();
                }
                i++;
            }, 500);

            function startans() {
            devices.innerHTML += `<span class="output-cmd">Adding Answer</span>`;

                const ansinter = setInterval(() => {
                    if (ans[k] !== null && ans[k] !== undefined) {
                        devices.innerHTML += `<span class="output-cmd">${ans[k]}</span>`;
                    }
                    if (k >= ans.length) {
                        clearInterval(ansinter);
                        devices.innerHTML += `<span class="output-cmd">Answer Added</span>`;
                        settrain();
                    }
                k++;

                }, 500);
            }
           
            function settrain() {
                localStorage.removeItem('console_qur');
                localStorage.removeItem('console_ans');
                localStorage.setItem('console', 'false');
                localStorage.setItem('train', 'true');
                var storageEvent = new StorageEvent('storage', {
                    key: 'train',
                    newValue: 'true'
                });
                window.dispatchEvent(storageEvent);
                }
            }
    }

    useEffect(() => {
        window.addEventListener('storage', trainChanged);
        return () => {
            window.removeEventListener('storage', trainChanged);
        };
    }, []);
        

    return (
        <>
         <div id="console">
            <div className="console p-0">
                <div className="console">
                    <div className="console-inner">
                        <div id="outputs">
                            Console Log : <span className="text-success"> $<span>{name}</span> </span>
                        </div>
                        <div id="devices">
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}