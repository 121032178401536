import { Link } from "react-router-dom"
export default function Footer() {
    return (<>
            <footer className="footer1">
            <div className="footer-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 col-sm-5 col-xs-12">
                            <div className="footer-content logo-footer">
                                <div className="footer-head">
                                    <div className="footer-logo">
                                    	<Link className="footer-black-logo" to="#"><div className="d-flex align-items-center "> <img className="logos" src="https://bots.bimash.com.np/assets/img/logo.png" alt=""/> <h3 className="m-0">Habibi</h3></div></Link>
                                    	<Link className="footer-white-logo" to="#"><div className="d-flex align-items-center "> <img className="logos" src="https://bots.bimash.com.np/assets/img/logo.png" alt=""/> <h3 className="m-0 text-white">Habibi</h3></div></Link>
                                    </div>
                                    <p>
                                       Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex ratione, alias illo adipisci facilis omnis, harum ab assumenda in aliquam veniam nam qui. Tempora reiciendis magnam veniam error ipsa iusto!
                                    </p>
                                    <div className="subs-feilds">
                                        <div className="suscribe-input">
                                            <input type="email" className="email form-control width-80" id="sus_email" placeholder="Type Email" />
                                            <button type="submit" id="sus_submit" className="add-btn">Subscribe</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-3 col-xs-12">
                            <div className="footer-content">
                                <div className="footer-head">
                                    <h4>Services Link</h4>
                                    <ul className="footer-list">
                                        <li><Link to="#">Business</Link></li>
                                        <li><Link to="#">Agency </Link></li>
                                        <li><Link to="#">Social media</Link></li>
                                        <li><Link to="#">Branding</Link></li>
                                        <li><Link to="#">Design </Link></li>
                                    </ul>
                                    <ul className="footer-list hidden-sm">
    									<li><Link to="#">Search engine</Link></li>
                                        <li><Link to="#">Online support</Link></li>
                                        <li><Link to="#">Development</Link></li>
                                        <li><Link to="#">Pay per click</Link></li>
                                        <li><Link to="#">Event activation</Link></li>
    								</ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-4 col-xs-12">
                            <div className="footer-content last-content">
                                <div className="footer-head">
                                    <h4>Information</h4>
                                    <div className="footer-contacts">
    									<p><span>Tel :</span> +0890-564-5644</p>
    									<p><span>Email :</span> info@bimash.com.np</p>
    								</div>
                                    <div className="footer-icons">
                                        <ul>
                                            <li>
                                                <Link to="#">
                                                    <i className="fab fa-facebook"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    <i className="fab fa-twitter"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    <i className="fab fa-linkedin"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    <i className="fab fa-github"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    <i className="fab fa-instagram"></i>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-area-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-xs-12">
                            <div className="copyright">
                                <p>
                                    Copyright © 2022
                                    <Link className="text-success" to="http://bimash.com.np/"> bimash.com.np</Link> All Rights Reserved
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </>)
}