import '../../css/argonstyle.css';
import { NavLink } from 'react-router-dom';
import { access_token } from "../../Auth/localStorageVariable";
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { access_id } from '../../Auth/localStorageVariable';
export default function ProfileNav() {
    const [selectedbot , setselectedbot] = useState(localStorage.getItem('bot_id'));
    function closenav() {
        var element = document.getElementById("sidenav-main");
        element.classList.toggle("d-none");
    }
    if (access_token === null || access_token === undefined || access_token === 'undefined' || access_token === '') {
            window.location.href = "/";
    } 

    const [botlist, setmybots] = useState([]);
    useEffect(() => {
        setselectedbot(localStorage.getItem('bot_id'));
        axios.get(process.env.REACT_APP_API_URL+'getallbot', {
            params: {  'user_id': access_id },
            headers: {    'Authorization': 'Bearer ' + access_token, 'Content-Type': 'application/json' },
        }).then(function (response) {
            setmybots(response.data.data);
        }).catch(function (error) {
            console.log(error);
        });
    }, []);

    function openbot(botid) {
        console.log(botid);
        axios.get(process.env.REACT_APP_API_URL+'getbot', {
            params: {
                'user_id': access_id,
                'bot_id': botid
            },
            headers: {    'Authorization': 'Bearer ' + access_token, 'Content-Type': 'application/json' },
        }).then(function (response) {
            localStorage.setItem('bot_id', botid);
            localStorage.setItem('botname', response.data.data[0].name);
            localStorage.setItem('bottoken', response.data.data[0].token);
            window.location.reload();
        }).catch(function (error) {
            console.log(error);
        });
    }
    return (
        <>
    <aside className="sidenav d-none d-xl-block bg-white navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4 ps ps--active-y"
        id="sidenav-main">
        <div className="sidenav-header">
            <div className="navbar-brand m-0" >
                <div className="row">
                    <div className="col-2 p-0 m-0">
                        <img src="https://chat.bimash.com.np/assets/images/bot.png" className="rounded-circle navbar-brand-img h-100" alt="main_logo" />
                    </div>
                    <div className="col-10 p-0 m-0">
                    <select name="" className='form-select' value={selectedbot} id=""  onChange={(event) => openbot(event.target.value)} >
                        {
                            botlist.map((resdata) => {
                                return (
                                  
                                    <option key={resdata.botid} value={resdata.botid} className="card-body ">
                                        {resdata.name}
                                        </option>
                                    
                                    )
                            })
                        }
                        </select>
                    </div>
                       
                </div>
                <i onClick={closenav} className="d-xl-none float-end fa-solid fa-times" data-bs-toggle="tooltip" data-bs-placement="right" title="Collapse"></i>
            </div>
        </div>
        <hr className="horizontal dark mt-0" />
        <div className="collapse navbar-collapse w-auto h-auto ps" id="sidenav-collapse-main">
            <ul className="navbar-nav">
                <li className="nav-item mt-3">
                    <h6 className="ps-4  ms-2 text-uppercase text-xs font-weight-bolder opacity-6">Dashboard</h6>
                </li>
                <li className="nav-item">
                    <NavLink to="/Profile/BotDashboard" className="nav-link ">
                        <span className="icon pb-0 text-center align-items-center justify-content-center">
                            <i className="h5 fa-solid text-dark fa-lock"></i>
                        </span>
                        <span className="nav-link-text ms-1">Dashboard</span>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <hr className="horizontal dark" />
                    <h6 className="ps-4  ms-2 text-uppercase text-xs font-weight-bolder opacity-6">Train Bot</h6>
                </li>   
                <li className="nav-item">
                    <NavLink to="/Profile/Domain" className="nav-link ">
                        <span className="icon pb-0 text-center align-items-center justify-content-center">
                            <i className="h5 fa-duotone text-primary fa-folder"></i>
                        </span>
                        <span className="nav-link-text ms-1">Domain</span>
                    </NavLink>
                    <NavLink to="/Profile/Intents" className="nav-link ">
                        <span className="icon pb-0 text-center align-items-center justify-content-center">
                            <i className="h5 fa-duotone text-danger fa-chart-network"></i>
                        </span>
                        <span className="nav-link-text ms-1">Intents</span>
                    </NavLink>
                    <NavLink to="/Profile/Entity" className="nav-link">
                        <span className="icon pb-0 text-center align-items-center justify-content-center">
                            <i className="h5 fa-light fa-brain-circuit text-info"></i>
                        </span>
                        <span className="nav-link-text ms-1">Entity</span>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <hr className="horizontal dark" />
                    <h6 className="ps-4  ms-2 text-uppercase text-xs font-weight-bolder opacity-6">Implement Bot</h6>
                </li> 
                <li className="nav-item">
                    <NavLink to="/Profile/Implement" className="nav-link ">
                        <span className="icon pb-0 text-center align-items-center justify-content-center">
                            <i className="h5 fa-duotone text-primary fa-message-bot"></i>
                        </span>
                        <span className="nav-link-text ms-1">Implementation</span>
                    </NavLink>
                    <NavLink to="/Profile/ApiDocs" className="nav-link ">
                        <span className="icon pb-0 text-center align-items-center justify-content-center">
                            <i className="h5 fa-duotone text-danger fa-book"></i>
                        </span>
                        <span className="nav-link-text ms-1">Api Docs</span>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <hr className="horizontal dark" />
                    <h6 className="ps-4  ms-2 text-uppercase text-xs font-weight-bolder opacity-6">Messages</h6>
                </li> 
                <li className="nav-item">
                    <NavLink to="/Profile/Chats" className="nav-link ">
                        <span className="icon pb-0 text-center align-items-center justify-content-center">
                            <i className="h5 fa-duotone text-success fa-messages"></i>
                        </span>
                        <span className="nav-link-text ms-1">Chats</span>
                    </NavLink>
                </li>
            </ul>
          
        </div>
       
    </aside>
            
        </>
    )
}

