import Nav from "../model/homepage/Nav";
import HeroPage from "../model/homepage/HeroPage";
import WelcomeArea from "../model/homepage/WelcomeArea";
import Services from "../model/homepage/Services";
import Feature from "../model/homepage/Features";
import AiFeatures from "../model/homepage/AiFeatures";
import News from "../model/homepage/News";
import Footer from "../model/homepage/Footer";
import Login from "../Auth/Login";
import React, { useEffect } from "react";

export default function HomePage() {
   useEffect(() => {
    if (document.querySelector('.mean-expand')) {
      document.querySelectorAll('.mean-expand').forEach((item) => {
        item.addEventListener('click', (e) => {
            e.preventDefault();
            const navli = e.target.parentElement.querySelector('ul').classList;
          if (navli.contains('d-none')){
            navli.remove('d-none');
          } else {
            navli.add('d-none');
          }
        })
      })
    }

    if(document.querySelector('#open_nav')) {
      document.querySelector('#open_nav').addEventListener('click', (e) => {
        e.preventDefault();
        if (document.querySelector('#mean-nav').classList.contains('d-none')){
          document.querySelector('#mean-nav').classList.remove('d-none');
        } else {
          document.querySelector('#mean-nav').classList.add('d-none');
        }
      })
    }

    if(document.querySelector('#sticker')){
      var sticky = document.querySelector('#sticker');
      window.addEventListener('scroll', function () {
        var scroll = window.pageYOffset;
        if (scroll < 300) {
          sticky.classList.remove('stick');
        } else {
          sticky.classList.add('stick');
        }
      });
    }
    
  }, []);
  return (
      <>
        <Nav
            theme = ""
        />
      <HeroPage />  
      <WelcomeArea />
      <Services />
      <Feature />
      <AiFeatures/>
          <News />
        <Login/>
          
    <Footer/>
    </>
  );
}
