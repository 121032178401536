import { Link } from "react-router-dom"
export default function AiFeatures() {
    return (
        <>
            <div className="tab-area fix area-padding">
            <div className="container">
                <div className="row">
    				<div className="col-md-5 col-sm-6 col-xs-12">
    					<div className="tab-menu">
    						<ul className="nav nav-tabs" role="tablist">
    							<li className="active">
    							    <Link to="#p-view-1" role="tab" data-toggle="tab">
                                            <span className="cha-icon">
                                                <i className="flaticon-017-automation"></i>
                                            </span>
    							        <span className="title-date">
    							        	<span className="cha-title">Chatbot Plugin</span>
    							            <span className="cha-date">Elevate website engagement with our Chat Plugin </span>
    							        </span>
    							    </Link>
    							</li>
    							<li>
    							    <Link to="#p-view-2" role="tab" data-toggle="tab">
    						            <span className="cha-icon"><i className="flaticon-015-artificial-intelligence"></i></span>
    							        <span className="title-date">
    							        	<span className="cha-title">Efficient Customer Service</span>
    							            <span className="cha-date">Simplify customer service with our Chatbot Plugin</span>
    							        </span>
    							    </Link>
    							</li>
    							<li>
    						        <Link to="#p-view-3" role="tab" data-toggle="tab">
    						            <span className="cha-icon"><i className="flaticon-030-human"></i></span>
    							        <span className="title-date">
    							        	<span className="cha-title">Revolutionary Website Communication</span>
    							            <span className="cha-date">Connect with your audience using our Web Chat Plugin</span>
    							        </span>
    							    </Link>
    							</li>
    							<li>
    						        <Link to="#p-view-4" role="tab" data-toggle="tab">
    						           <span className="cha-icon"><i className="flaticon-091-ai"></i></span>
    							        <span className="title-date">
    							        	<span className="cha-title">Next Level Business Success</span>
    							            <span className="cha-date">Boost your business with our Powerful Chatbot Plugin</span>
    							        </span>
    							    </Link>
    							</li>
    						</ul>
    					</div>
    				</div>
    				<div className="col-md-7 col-sm-6 col-xs-12">
    					<div className="tab-content">
    						<div className="tab-pane active" id="p-view-1">
    							<div className="tab-inner">
    								<div className="single-machine">
    								    <div className="machine-text">
    									    <h3>Advanced Chat Plugin for Seamless Customer Interaction</h3>
    									    <p>Revolutionize the way you communicate with your customers. Our advanced chat plugin provides seamless, real-time interaction for enhanced website engagement and increased customer satisfaction.</p>
    									</div>
    									<div className="tabe-img">
    										<img src="assets/services/s1.png" alt=""/>
    									</div>
    								</div>
    							</div>
    						</div>
    						<div className="tab-pane" id="p-view-2">
    							<div className="tab-inner">
    								<div className="single-machine">
    								    <div className="machine-text">
    									    <h3> Cutting-Edge Chatbot Plugin for Efficient Customer Service</h3>
    									    <p>Streamline your customer service with our cutting-edge chatbot plugin. Instantly resolve customer queries and provide real-time support for improved efficiency and customer satisfaction.</p>
    									</div>
    									<div className="tabe-img">
    										<img src="assets/services/s2.png" alt=""/>
    									</div>
    								</div>
    							</div>
    						</div>
    						<div className="tab-pane" id="p-view-3">
    							<div className="tab-inner">
    								<div className="single-machine">
    								    <div className="machine-text">
    									    <h3> Innovative Web Chat Plugin for Enhanced Communication</h3>
    									    <p>Take your website communication to the next level with our innovative web chat plugin. Connect with your audience in real-time and elevate your website's customer engagement.</p>
    									</div>
    									<div className="tabe-img">
    										<img src="assets/services/s3.png" alt=""/>
    									</div>
    								</div>
    							</div>
    						</div>
    						<div className="tab-pane" id="p-view-4">
    							<div className="tab-inner">
    								<div className="single-machine">
    								    <div className="machine-text">
    									    <h3>Powerful Chatbot Plugin for Next Level Business Success</h3>
    									    <p>Achieve next level business success with our powerful chatbot plugin. Simplify customer service, boost sales, and improve customer satisfaction for enhanced business growth.</p>
    									</div>
    									<div className="tabe-img">
    										<img src="assets/services/s4.png" alt=""/>
    									</div>
    								</div>
    							</div>
    						</div>
    					</div>
    				</div>
    			</div>
            </div>
        </div>
        </>
    )
}