import { Link } from "react-router-dom"
import { useEffect , useState } from 'react';
export default function Navs(theme) {
    const [isLoading, setIsLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('access_token'));
  useEffect(() => {
      setIsLoading(false);
      startnavsthings()
      function StorageChanged() {
          if (localStorage.getItem('access_token') !== null && localStorage.getItem('access_token') !== undefined && localStorage.getItem('access_token') !== 'undefined' && localStorage.getItem('access_token') !== '') {
              setIsLoggedIn(localStorage.getItem('access_token'));
          } else {
              localStorage.removeItem('access_token');
              setIsLoggedIn(false);
          }
      }
      if (localStorage.getItem('access_token') !== null && localStorage.getItem('access_token') !== undefined && localStorage.getItem('access_token') !== 'undefined' && localStorage.getItem('access_token') !== '') {
            setIsLoggedIn(localStorage.getItem('access_token'));
        } else {
            localStorage.removeItem('access_token');
            setIsLoggedIn(false);
        }

    window.addEventListener('storage', StorageChanged);
    return () => {
    window.removeEventListener('storage', StorageChanged);
    };
  }, []);
    
  useEffect(() => {
    if (!isLoading) {
        startnavsthings()
    }
  }, [isLoading]);
    var color = '';
    if (theme.theme === undefined || theme.theme === null || theme.theme === "") {
         color = '';
    } else {
         color = `text-`+theme.theme;
    }
    return (
        <>
        <header className="header-one">
            <div id="sticker" className="header-area hidden-xs ">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 col-sm-3">
                            <div className="logo">
                                <Link className="navsbar-brand page-scroll black-logo" to="/">
                                        <div className="d-flex align-items-center">
                                            <img className="logos" src="https://bots.bimash.com.np/assets/img/logo.png" alt="" />
                                            <h4 className={color+` m-0  `}>Habibi</h4>
                                        </div>
                                </Link>
                                <Link className="navsbar-brand page-scroll white-logo" to="/">
                                    <div className="d-flex align-items-center">
                                        <img className="logos" src="https://bots.bimash.com.np/assets/img/logo.png" alt="" />
                                        <h4 className=" m-0 text-white ">Habibi</h4>
                                    </div>        
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-9 col-sm-9">
                                <div className="header-right-link">
                                    {isLoggedIn ? (
                                        <Link className="s-menu" to="/Profile">Profile</Link>
                                    ) : (
                                        <Link className="s-menu" data-bs-toggle="modal" data-bs-target="#exampleModal">Sign in</Link>
                                    )
                                    }
                            </div>
                            <nav className="navsbar navsbar-default">
                                <div className="collapse navsbar-collapse" id="navsbar-example">
                                    <div className="main-menu">
                                        <ul className="navs navsbar-navs navsbar-right">
                                            <li><Link to="/">Home</Link> </li>
                                            <li><Link to="/About">About</Link></li>
                                            <li><Link to="/Services">Services</Link></li>
                                            <li><Link to="/FAQ">FAQ</Link></li>
                                            <li><Link to="/Blog">Blog</Link></li>
                                            <li><Link to="/Contact">contacts</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mobile-menu-area hidden-lg hidden-md hidden-sm">
                <div className="container mean-container">
                    <div className="mean-bar">
                        <img className="logos" src="https://bots.bimash.com.np/assets/img/logo.png" alt=""/>
                        <i  onClick={() => openorclosenav()} className="fa-solid fa-bars" >
                        <span></span><span></span><span></span></i>
                        <nav className="mean-navs d-none" id="mean-navs">
                            <ul>
                                 <li><Link to="/">Home</Link> </li>
                                <li><Link to="/About">About</Link></li>
                                <li><Link to="/Services">Services</Link></li>
                                <li><Link to="/FAQ">FAQ</Link></li>
                                <li><Link to="/Blog">Blog</Link></li>
                                <li><Link to="/Contact">contacts</Link></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
        </>
    )
    }


function startnavsthings() {
    if(document.querySelector('#sticker')){
      var sticky = document.querySelector('#sticker');
      window.addEventListener('scroll', function () {
        var scroll = window.pageYOffset;
        if (scroll < 300) {
          sticky.classList.remove('stick');
        } else {
          sticky.classList.add('stick');
        }
      });
    }
}
function openorclosenav() {
    if (document.querySelector('#mean-navs').classList.contains('d-none')){
        document.querySelector('#mean-navs').classList.remove('d-none');
    } else {
        document.querySelector('#mean-navs').classList.add('d-none');
    }
}

window.addEventListener('load', startnavsthings);
