import Footer from "../model/homepage/Footer"
import Nav from "../model/homepage/Nav"
import PageArea from "../model/homepage/PageArea"
import { Link } from "react-router-dom"
export default function BlogPreview() {
    return (
        <>
            <Nav
                theme = "white"
            />
             <PageArea
                title="Blog Details"
                subtitle = 'The universal acceptance has given a tremendous'
            />
            <div class="blog-area fix area-padding">
                <div class="container">
                    <div class="row">
                        <div class="blog-details">
                            <div class="col-md-8 col-sm-8 col-xs-12">
                                <article class="blog-post-wrapper">
                                    <div class="blog-banner">
                                        <Link to="#" class="blog-images">
                                            <img src="img/blog/b1.jpg" alt=""/>
                                        </Link>
                                        <div class="blog-content">
                                            <div class="blog-meta">
                                                <span class="date-type">
                                                    20 july, 2019
                                                </span>
                                                <span class="comments-type">
                                                    <i class="fa fa-comment-o"></i>
                                                    13
                                                </span>
                                            </div>
                                            <div className="blog-data">
                                                            
                                            </div>
                                        </div>
                                    </div>
                                </article>
                                <div class="clear"></div>
                                <div class="related-post">
                                    <div class="comments-heading">
                                        <h3>Related post</h3>
                                    </div>
                                    <div class="related-post-list">
                                        <div class="recent-single-post">
                                            <div class="post-img">
                                                <Link to="#">
                                                    <img src="img/blog/b1.jpg" alt=""/>
                                                </Link>
                                            </div>
                                            <div class="pst-content">
                                                <p><Link to="#">We offer professional Consultant services.</Link></p>
                                                <span class="date-type">
                                                    27 Jan / 2018
                                                </span>
                                            </div>
                                        </div>
                                        <div class="recent-single-post">
                                            <div class="post-img">
                                                <Link to="#">
                                                    <img src="img/blog/b2.jpg" alt=""/>
                                                </Link>
                                            </div>
                                            <div class="pst-content">
                                                <p><Link to="#">Montril is an firm and general group.</Link></p>
                                                <span class="date-type">
                                                    20 June / 2018
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="clear"></div>
                                <div class="single-post-comments">
                                    <div class="comments-area">
                                        <div class="comments-heading">
                                            <h3>4 comments</h3>
                                        </div>
                                        <div class="comments-list">
                                            <ul>
                                                <li>
                                                    <div class="comments-details">
                                                        <div class="comments-list-img">
                                                            <img src="img/blog/avater2.png" alt="post-author" />
                                                        </div>
                                                        <div class="comments-content-wrap">
                                                            <span>
                                                                <b><Link to="#">Alens</Link></b>
                                                                Post author
                                                                <span class="post-time">Jan 6, 2018</span>
                                                                <Link to="#">Reply</Link>
                                                            </span>
                                                            <p>Quisque semper nunc vitae erat pellentesque, ac placerat arcu consectetur</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li class="threaded-comments">
                                                    <div class="comments-details">
                                                        <div class="comments-list-img">
                                                            <img src="img/blog/avater1.png" alt="post-author" />
                                                        </div>
                                                        <div class="comments-content-wrap">
                                                            <span>
                                                                <b><Link to="#">admin</Link></b>
                                                                Post author
                                                                <span class="post-time">Jan 7, 2018</span>
                                                                <Link to="#">Reply</Link>
                                                            </span>
                                                            <p>Quisque orci nibh, porta vitae sagittis sit amet, vehicula vel mauris. Aenean at justo dolor. Fusce ac sapien bibendum, scelerisque libero nec</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="comments-details">
                                                        <div class="comments-list-img">
                                                            <img src="img/blog/avater2.png" alt="post-author" />
                                                        </div>
                                                        <div class="comments-content-wrap">
                                                            <span>
                                                                <b><Link to="#">Andre</Link></b>
                                                                Post author
                                                                <span class="post-time">Nov 11, 2017</span>
                                                                <Link to="#">Reply</Link>
                                                            </span>
                                                            <p>Quisque semper nunc vitae erat pellentesque, ac placerat arcu consectetur</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li class="threaded-comments">
                                                    <div class="comments-details">
                                                        <div class="comments-list-img">
                                                            <img src="img/blog/avater1.png" alt="post-author" />
                                                        </div>
                                                        <div class="comments-content-wrap">
                                                            <span>
                                                                <b><Link to="#">admin</Link></b>
                                                                Post author
                                                                <span class="post-time">Nov 12, 2017</span>
                                                                <Link to="#">Reply</Link>
                                                            </span>
                                                            <p>Quisque orci nibh, porta vitae sagittis sit amet, vehicula vel mauris. Aenean at justo dolor. Fusce ac sapien bibendum, scelerisque libero nec</p>
                                                        </div>
                                                    </div>
                                                </li>									
                                            </ul>
                                        </div>
                                    </div>				
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12">
                                <div class="left-head-blog right-side">
                                    <div class="left-blog-page">
                                        <form action="#">
                                            <div class="blog-search-option">
                                                <input type="text" placeholder="Search..." />
                                                <button class="button" type="submit">
                                                    <i class="fa fa-search"></i>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="left-blog-page">
                                        <div class="left-blog">
                                            <h4>categories</h4>
                                            <ul>
                                                <li><Link to="#">Business</Link><span>12</span></li>
                                                <li><Link to="#">Agency </Link><span>17</span></li>
                                                <li><Link to="#">Media</Link><span>07</span></li>
                                                <li><Link to="#">Social</Link><span>21</span></li>
                                                <li><Link to="#">Photoshop</Link><span>14</span></li>
                                                <li><Link to="#">development</Link><span>10</span></li>
                                                <li><Link to="#">Design</Link><span>15</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="left-blog-page">
                                        <div class="left-blog">
                                            <h4>recent post</h4>
                                            <div class="recent-post">
                                                <div class="recent-single-post">
                                                    <div class="post-img">
                                                        <Link to="#">
                                                            <img src="img/blog/b1.jpg" alt=""/>
                                                        </Link>
                                                    </div>
                                                    <div class="pst-content">
                                                        <p><Link to="#">We offer professional Consultant services.</Link></p>
                                                        <span class="date-type">
                                                            26 Jan / 2018
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="recent-single-post">
                                                    <div class="post-img">
                                                        <Link to="#">
                                                            <img src="img/blog/b2.jpg" alt=""/>
                                                        </Link>
                                                    </div>
                                                    <div class="pst-content">
                                                        <p><Link to="#">Montril is an firm and general group.</Link></p>
                                                        <span class="date-type">
                                                            20 June / 2018
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="recent-single-post">
                                                    <div class="post-img">
                                                        <Link to="#">
                                                            <img src="img/blog/b3.jpg" alt=""/>
                                                        </Link>
                                                    </div>
                                                    <div class="pst-content">
                                                        <p><Link to="#">Business man always think positive.</Link></p>
                                                        <span class="date-type">
                                                            26 Feb / 2018
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="recent-single-post">
                                                    <div class="post-img">
                                                        <Link to="#">
                                                            <img src="img/blog/b4.jpg" alt=""/>
                                                        </Link>
                                                    </div>
                                                    <div class="pst-content">
                                                        <p><Link to="#">Man can change business policy all time.</Link></p>
                                                        <span class="date-type">
                                                            13 Nov / 2018
                                                        </span>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="left-blog-page">
                                        <div class="left-tags blog-tags">
                                            <div class="popular-tag left-side-tags left-blog">
                                                <h4>popular tags</h4>
                                                <ul>
                                                    <li><Link to="#">Business</Link></li>
                                                    <li><Link to="#">Agency </Link></li>
                                                    <li><Link to="#">Media</Link></li>
                                                    <li><Link to="#">Social</Link></li>
                                                    <li><Link to="#">Photoshop</Link></li>
                                                    <li><Link to="#">Seo</Link></li>
                                                    <li><Link to="#">development</Link></li>
                                                    <li><Link to="#">Search</Link></li>
                                                    <li><Link to="#">Design</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}