import * as echarts from 'echarts';
import { useEffect, useState } from 'react';

export default function WebTrafficUniq({ data }){
    const id = 'uniq_visitors_ovl_grph';
    const [xdata , setXdata] = useState([]);
    const [ydata , setYdata] = useState([]);
    const [dataminmax , setDataminmax] = useState({
        min : 0,
        max : 0
    });
    const [totalreq , setTotalreq] = useState(0);
    
    useEffect(() => {
        if(data.length > 0){
            var total = 0;
            var newxdata = [];
            var newydata = [];
            data.map((item)=>{
                newxdata.push(item.date.date);
                newydata.push(item.uniq.uniques);
                total += item.uniq.uniques;
            }
            )
            setXdata(newxdata);
            setYdata(newydata);
            if(total > 1000){
                total = (total/1000).toFixed(2) + 'K';
            }
            // get min and max value from newxdata
            var min = Math.min(...newydata);
            var max = Math.max(...newydata);
            setDataminmax({
                min : min,
                max : max
            })
    
            setTotalreq(total);
        }
    }, [data])

    function initOverLinechart(id){
        var chartDom = document.getElementById(id);
        var myChart = echarts.getInstanceByDom(chartDom);
        if (myChart === undefined) {
            myChart = echarts.init(chartDom);
        }
        var option;

        option = {
        visualMap: [
            {
                show: false,
                type: 'continuous',
                seriesIndex: 0,
                min: dataminmax.min,
                max: dataminmax.max
            }
            ],
        gradientColor: ['#00c6ff', '#F0F8FF'],
        xAxis: {
            show : true,
            type: 'category',
            boundaryGap: false,
            data: xdata
        },
        yAxis: {
            show : true,
            type: 'value'
        },
        stateAnimation : {
            duration : 3000,
            easing : 'cubicOut',
        },
        
        tooltip : {
            trigger: 'axis',
            formatter: '{b} <br/> {c}'
        },
        series: [
            {
            data: ydata,
            type: 'line',
            areaStyle: {},
                label : {
                    position : 'top'
                }
            }
        ]
        };

        option && myChart.setOption(option);
    }
    useEffect(() => {
        var width = document.getElementById('v-pills-requests').offsetWidth;
        document.getElementById(id).style.width = width + 'px';
        if(document.getElementById(id)){
            initOverLinechart(id);
        }
    }, [id , xdata , ydata])
    return <>
        <div>
            <hr />
            <div className="px-5 w-100 d-flex justify-content-between">
                <div className=' p-3'>
                    <h6 className='m-0'>Total Unique Visitors</h6>
                    <h5 className='m-0'>{totalreq}</h5>
                </div>
                <div className=' p-3'>
                    <h6 className='m-0'>Maximum Unique Visitors</h6>
                    <h5 className='m-0'>{dataminmax.max}</h5>
                </div>
                <div className=' p-3'>
                    <h6 className='m-0'>Maximum Unique Visitors</h6>
                    <h5 className='m-0'>{dataminmax.min}</h5>
                </div>
            </div>
          
            <div id={id} style={{width: '100%', height: '400px'}}>
            </div>
        </div>
    </>


}