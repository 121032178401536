import ProfileNav from "../ProfileNav"
import PageName from "../model/pageName";
export default function ApiDocs() {
  
  return (
      <>   
           <div className="bg-gray-100">
          <ProfileNav />
            <div className="bg-gray-100 main-content position-relative vh-100 ">
              <PageName
                  name='Api Docs'
              />
              <div className=" card shadow-none mx-3 bg-transparent ">
                <div className="row">
                  <div className="col-12">
                    <div className="card p-4">
                        <iframe 
                        
                        style={{
                            width: '100%',
                            height: '100vh'
                        }}
                        src="https://apigg.bimash.com.np/api-docs/" frameBorder="0"></iframe>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </>
  );
}
