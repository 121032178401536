export default function PageArea(data) {
    return (
        <>
            <div class="page-area">
                <div class="breadcumb-overlay"></div>
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 col-sm-12 col-xs-12">
                                <div class="breadcrumb text-center">
                                <div class="section-headline white-headline text-center">
                                    <h3>{data.title}</h3>
                                </div>
                                <ul>
                                    <li><h5 className="text-white">{data.subtitle}</h5></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}