import * as echarts from 'echarts';
import { useEffect, useState } from 'react';

export default function WebTrafficReq({ data }){
    const id = 'requests_ovl_grph';
    const [ydatacached , setYdataChached] = useState([]);
    const [ydatauncached , setYdataunChached] = useState([]);
    
    const [xdata , setXdata] = useState([]);
    const [ydata , setYdata] = useState([]);
    const [dataminmax , setDataminmax] = useState({
        min : 0,
        max : 0
    });
    const [totalreq , setTotalreq] = useState(0);
    const [chached , setChached] = useState(0);
    const [uncached , setUncached] = useState(0);

    useEffect(() => {
        if(data.length > 0){
            var total = 0;
            var cachedreq = 0;
            var nocache = 0;
            var newxdata = [];
            var newydata = [];
            var newydatacached = [];
            var newydatauncached = [];
            data.map((item)=>{
                newxdata.push(item.date.date);
                newydata.push(item.sum.requests);
                newydatacached.push(item.sum.cachedRequests);
                newydatauncached.push(item.sum.requests - item.sum.cachedRequests);
                total += item.sum.requests;
                cachedreq += item.sum.cachedRequests;

            }
            )
            nocache = ((total - cachedreq)/1000).toFixed(2) + 'K'
            setUncached(nocache);
            setXdata(newxdata);
            setYdata(newydata);
            total = (total/1000).toFixed(2) + 'K';
            var min = Math.min(...newydata);
            var max = Math.max(...newydata);
            setDataminmax({
                min : min,
                max : max
            })
            setChached(cachedreq);
            setYdataChached(newydatacached);
            setYdataunChached(newydatauncached);
            setTotalreq(total);
        }
    }, [data])
    function initOverLinechart(id){
        var chartDom = document.getElementById(id);
        var myChart = echarts.getInstanceByDom(chartDom);
        if (myChart === undefined) {
            myChart = echarts.init(chartDom);
        }
        var option;

        option = {
        visualMap: [
            {
                show: false,
                type: 'continuous',
                seriesIndex: 0,
                min: dataminmax.min,
                max: dataminmax.max
            }
            ],
        gradientColor: ['#ff3e1d', '#F0F8FF'],
        xAxis: {
            show : true,
            type: 'category',
            boundaryGap: false,
            data: xdata
        },
        yAxis: {
            show : true,
            type: 'value'
        },
        stateAnimation : {
            duration : 3000,
            easing : 'cubicOut',
        },
        
        tooltip : {
            trigger: 'axis',
            formatter: '{b} <br/> {a} : {c} <br/>  {a1} : {c1} <br/> {a2} : {c2}'
        },
        series: [
            {
              name: 'Total Requests',
              type: 'line',
              emphasis: {
                focus: 'series'
              },
              data: ydata
            },
            {
              name: 'Cached Requests',
              type: 'line',
              emphasis: {
                focus: 'series'
              },
              data : ydatacached
            },
            {
                name: 'unCached Requests',
                type: 'line',
                emphasis: {
                  focus: 'series'
                },
                data : ydatauncached
              }
          ]
        };

        option && myChart.setOption(option);
    }
   useEffect(() => {
        if( document.getElementById(id)){
            initOverLinechart(id);
        }
    }, [id , xdata , ydata])
    return <>
        <div>
            <hr />
            <div className="px-5 w-100 d-flex justify-content-between">
                <div className=' p-3'>
                    <h6 className='m-0'>Total Reqests</h6>
                    <h5 className='m-0'>{totalreq}</h5>
                </div>
                <div className=' p-3'>
                    <h6 className='m-0'>Cached Reqests</h6>
                    <h5 className='m-0'>{chached}</h5>
                </div>
                <div className=' p-3'>
                    <h6 className='m-0'>Uncached Reqests</h6>
                    <h5 className='m-0'>{uncached}</h5>
                </div>
            </div>
          
            <div id={id} style={{width: '100%', height: '400px'}}>
            </div>
        </div>
    </>


}