import { useEffect, useState } from "react";
import ProfileNav from "../ProfileNav"
import PageName from "../model/pageName";
import Instance from "../../../axios";
export default function Chats() {
    const [messages , setMessages] = useState([]);
    useEffect(() => {
        allchats();
    }, []);

    function allchats(){
        Instance.get('/bot/allchats').then((response) => {
            setMessages(response.data);
           setTimeout(() => {
            scrollToBottom();
           }, 1000);
        })
    }
    function scrollToBottom() {
        var objDiv = document.querySelector(".messagebox");
        objDiv.scrollTop = objDiv.scrollHeight;
    }
    

    
  return (
      <>   
           <div className="bg-gray-100">
          <ProfileNav />
            <div className="bg-gray-100 main-content position-relative vh-100 ">
              <PageName
                  name='Dashboard'
              />
              <div className=" card shadow-none mx-3 bg-transparent ">
                <div className="row">
                  <div className="col-12">
                    <div className="card p-4">
                        <div className="card-body messagebox" id="messagebox" style={{
                            height: '75vh',
                            overflowY: 'auto'
                        }}>
                            { 
                                messages.length > 0 ?
                                (
                                    messages.map((message) => {
                                        // var extract last 6 character from message.user_id
                                        var last6 = message.user_id.substr(message.user_id.length - 6);
                                        return <div key={message.id} className={message.type}>
                                        <div>
                                            <small>{message.user_id}</small>
                                            <p
                                                style={{
                                                    backgroundColor: message.type === 'outgoing' ? `#${last6}` : '#f1f1f1',
                                                    color: message.type === 'incoming' ? `#${last6}` : '#f1f1f1',

                                                }}
                                                dangerouslySetInnerHTML={{ __html: message.message }} 
                                            ></p>
                                        </div>
                                    </div> 
                                    })
                                ):(
                                    <> </>
                                )
                            }
                        </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </>
  );
}
