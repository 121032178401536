import axios from 'axios';
import { useEffect, useState } from 'react';
import { access_id, access_token } from '../Auth/localStorageVariable';
import '../css/style.css';
import CreateBot from '../model/profile/pages/CreateBot';
export default function Profile() {
    const [botlist, setmybots] = useState([]);
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL+'getallbot', {
            params: {  'user_id': access_id },
            headers: {    'Authorization': 'Bearer ' + access_token, 'Content-Type': 'application/json' },
        }).then(function (response) {
            setmybots(response.data.data);
        }).catch(function (error) {
            console.log(error);
        });
    }, []);

    function openbot(botid) {
        axios.get(process.env.REACT_APP_API_URL+'getbot', {
            params: {
                'user_id': access_id,
                'bot_id': botid
            },
            headers: {    'Authorization': 'Bearer ' + access_token, 'Content-Type': 'application/json' },
        }).then(function (response) {
            localStorage.setItem('bot_id', botid);
            localStorage.setItem('botname', response.data.data[0].name);
            localStorage.setItem('bottoken', response.data.data[0].token);
            window.location.href = '/Profile/BotDashboard';
        }).catch(function (error) {
            console.log(error);
        });
    }
  return (
      <>   
        <div className="bg-gray-100 vh-100 d-flex justify-content-center flex-column align-items-center w-100">
                <h1>Select Bot</h1>
                <div className='mt-2 d-flex justify-content-center align-items-center w-100'>
                  {
                      botlist.map((resdata) => {
                          return (
                               <div key={resdata.botid} onClick={ ()=> openbot(resdata.botid)} className="card botcard  mx-3">
                                    <img className="botselection" src= {resdata.img} alt='bot profile'/>
                                    <div className="card-body ">
                                        {resdata.name}
                                    </div>
                                </div>
                            )
                      })
                    }
                   
                   
                    <div data-bs-toggle="modal" data-bs-target="#createbotmodal"  className="card botcard  mx-3">
                        <img className="botselection" alt='add more' src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9e/Plus_symbol.svg/1200px-Plus_symbol.svg.png" />
                      <div className="card-body">
                          Create Bot
                    </div>
                    </div> 
                </div>
          </div>
        <CreateBot/>
          
    </>
  );
}
