import React, { useEffect, useRef, useState } from 'react';
import { access_token, access_id, bottoken, bot_id } from "../../../Auth/localStorageVariable";

import Choices from 'choices.js';
import axios from 'axios';
export default function TrainForm({ name, domain, intent }) {
    const utteranceRef = useRef();
    const answerRef = useRef();
    const [formSubmitted, setFormSubmitted] = useState(false);
   
    useEffect(() => {
    const utteranceElement = utteranceRef.current;
    const answerElement = answerRef.current;

    const utteranceChoices = new Choices(utteranceElement, {
        removeItemButton: true,
        duplicateItemsAllowed: false,
        allowHTML: true,
    });

    const answerChoices = new Choices(answerElement, {
        removeItemButton: true,
        duplicateItemsAllowed: false,
        allowHTML: true,
        
    });
    const onKeyDown = (event) => {
        if (event.which === 13 || event.keyCode === 13) {
        event.preventDefault(); // Prevent form submission
        }
    };

    document.addEventListener("keydown", onKeyDown);
    if (formSubmitted) {
      // If the form was submitted, reset the form and destroy the Choices instance
        answerChoices.destroy();
      utteranceChoices.destroy();
        
    }
    return () => {
        utteranceChoices.destroy();
        answerChoices.destroy();
    };
        
    }, [domain , intent , formSubmitted ]);

    const trainbot = async (e) => {
        e.preventDefault();
        const utterance = utteranceRef.current.value;
        const answer = answerRef.current.value;
        const qur = utterance.split(',');
        const ans = answer.split(',');
        const domain_name = name.split('.')[0];
        const intent_name = name.split('.')[1];
       
        axios.post(process.env.REACT_APP_API_URL+`train-bot`,
            {
                qurs : qur,
                anss : ans ,
                token: bottoken,
                access_id,
                bot: bot_id,
                domain_id: domain,
                intent: intent_name,
                domain_name
            },
            {
                headers:   { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access_token },
            }
        )
          .then((response) => {
            if (response.data.status === 'trained') {
              setFormSubmitted(true);
              localStorage.setItem('trained_domain', domain); 
              localStorage.setItem('trained_intent', intent);
              localStorage.setItem('console', 'true');
              localStorage.setItem('console_qur', qur);
              localStorage.setItem('console_ans', ans );
              

              var storageEvent = new StorageEvent('storage', {
                  key: 'console',
                  newValue: 'true'
              });
              window.dispatchEvent(storageEvent);
              setTimeout(() => {
                  setFormSubmitted(false);
              }, 100);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }



  return (
    <>
      <form onSubmit={trainbot}>
        <div className="d-flex justify-content-between">
          <h5 className="font-weight-bolder">{name}</h5>
          <button  className="btn bg-gradient-info ms-auto mb-0 js-btn-next"  type="submit" title="Next">  Train </button>
        </div>
        <div className="multisteps-form__content">
          <label>Utterance</label>
          <input className="form-control"  id="choices-tags"  data-color="dark"  type="text"  placeholder="Enter something"  ref={utteranceRef} />
          <label>Answer</label>
          <input className="form-control"  id="choices-tag"  data-color="dark"  type="text"  placeholder="Enter something"   ref={answerRef} />
        </div>
      </form>
    </>
  );
}
