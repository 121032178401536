import axios from "axios";
import { useEffect, useState } from "react";
import { access_token, access_id, bottoken, bot_id } from "../../../Auth/localStorageVariable";

export default function TrainedData({ name, domain, intent }) {
const [trainedutterances, setutterances] = useState([]);
const [trainedanswer, setanswer] = useState([]);

function trainChanged() {
    var train_res = localStorage.getItem('train');
    if (train_res === 'true') {
        const domain = localStorage.getItem('trained_domain');
        const intent = localStorage.getItem('trained_intent');

        console.log('train changed');
        localStorage.removeItem('trained_domain');
        localStorage.removeItem('trained_intent');
        localStorage.setItem('train', 'false');
        gettrained(domain, intent);
    }
}

const gettrained = (domain, intent) => {
    console.log('gettrained called');
    axios.post(process.env.REACT_APP_API_URL+`gettrained`, {
        domain, intent, token: bottoken, access_id, bot: bot_id
    }, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + access_token
        },
    })
    .then(function (response) {
        setutterances(' ');
        setanswer(' ');
        setutterances(response.data.utterances);
        setanswer(response.data.answers);
        console.log(response.data);
    })
    .catch(function (error) {
        try {
            if (error.response.data.error !== undefined && error.response.data.error === 'Unauthorized') {
                localStorage.clear();
                window.location.href = '/';
            } 
        } catch (e) {
            console.log('Something Went Wrong')
        }
    });
};

useEffect(() => {
    if (domain && intent) {
        gettrained(domain, intent);
    }
}, [domain , intent ]);

useEffect(() => {
    window.addEventListener('storage', trainChanged);
    return () => {
        window.removeEventListener('storage', trainChanged);
    };
});

    return (
      <>   
         <div className="col-md-6"> 
            <div className="card">
                <div className="card-body">
                        <h5>Trained Data ( {name} )</h5>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6 p-0 m-0">
                            <h6 className="text-center">Utterance</h6>
                            <ul className="list-group border-radius-none">
                                    {
                                        trainedutterances.map((utterance) => {
                                            return (
                                                <li
                                                    key = {`utterance_`+utterance.id}
                                                    className="list-group-item d-flex justify-content-between align-items-center">
                                                    <p className="p-0 m-0" dangerouslySetInnerHTML={{ __html: utterance.utterance }} />
                                                    <span className="cursor-pointer badge badge-danger bg-transparent  badge-pill"><i className="fa-duotone fa-trash"></i></span>
                                                </li>
                                            )
                                        })
                                }
                            </ul>
                        </div>
                        <div className="col-md-6 p-0 m-0">
                            <h6 className="text-center">Answer</h6>
                            <ul className="list-group border-radius-none">
                                    { 
                                        trainedanswer.map((answer) => {
                                            return (
                                                <li
                                                    key={`answer_`+answer.id}
                                                    className="list-group-item d-flex justify-content-between align-items-center">
                                                    <p className="p-0 m-0" dangerouslySetInnerHTML={{ __html: answer.answer }} />
                                                    <span className="  badge badge-danger bg-transparent  badge-pill"><i className="cursor-pointer fa-duotone fa-trash"></i></span>
                                                </li>
                                            )
                                        })
                                    }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}