import { useState } from "react";
import axios from "axios";
export default function CreateBot() {
    const [botname, setBotName] = useState('');
    var access_token = localStorage.getItem("access_token");
    var access_id = localStorage.getItem("access_id");
    const createBot = (e) => {
        e.preventDefault();
    axios
      .post(process.env.REACT_APP_API_URL+`create-bot`, {
          botname,
          user_id : access_id
      },
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            },
        })
        .then(function (response) {
            console.log(response);
            localStorage.setItem("token", response.data.bot_token);
            localStorage.setItem("bot_id", response.data.bot_id);
            localStorage.setItem("bot_name", botname);
            window.location.href = '/Profile/BotDashboard';
        })
      .catch(function (error) {
        console.log(error);
      });
  };
    return (<>
     <div className="container">
        <div className="row">
            <div className="col-sm-3 col-6 mx-auto">
                <div className="modal fade loginmodal" id="createbotmodal" tabIndex="-1" aria-labelledby="createbotmodalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="card-header text-center pb-1">
                                    <div className="d-flex align-items-end justify-content-end">
                                        <div className='w-100'>
                                            <h4 className="mb-0" id="message_signup">Create Bot</h4>
                                        </div>
                                        <h6 className='px-3 py-2 cursor-pointer' data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-xmark"></i></h6>
                                    </div>                 
                                </div>
                                <div className="card-body pb-0">
                                        <form onSubmit={createBot} >
                                            <label htmlFor="botname">Bot Name</label>
                                            <input type="text" name="botname" value={botname} onChange={(e) => setBotName(e.target.value)} className="form-control" placeholder="Bot Name" />     
                                            <button className="btn btn-success mt-3" type="submit">Create</button>
                                        </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    </>)
}