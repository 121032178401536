import { useEffect, useState } from "react";
import Instance from '../../../../../axios';
import OverViewLine from "./deepanalytic/OverviewLine";
import CountryChart from "./deepanalytic/CountryChart";
import CountryMore from "./deepanalytic/CountryMore";
import WebTraffic from "./deepanalytic/WebTraffic";
export default function DeepAnalytic(){
    const [ datechoose , setDatechoose ] = useState(30);
    const [ deepanalytic , setDeepanalytic ] = useState([]);
    const [ domains , setDomains ] = useState([]);
    const [ domainchoose , setDomainchoose ] = useState('');
    const chooseable = [7,30,90,180,365];
    function getDomains(){
        Instance.get("analytic/domains").then((res)=>{
            if(res.data.status === "success"){
                setDomains(res.data.data)
                setDomainchoose(res.data.data[0].domain)
            }
        })
    }
    function start(){
        function datechooser(daysupto){
            var date = new Date();
            date.setDate(date.getDate() - daysupto);
            date = date.toISOString().slice(0, 10);
            return date;
        }
        var start_date = datechooser(datechoose);
        var end_date = new Date();
        end_date.setDate(end_date.getDate());
        end_date = end_date.toISOString().slice(0, 10);
        
        Instance.post("/analytic/deepAnalytics" 
        ,{
            domain : domainchoose,
            start_date : start_date,
            end_date : end_date
        }
        ).then((res)=>{
            if(res.data.status === "success"){
                setDeepanalytic(res.data.data)
            }
        })
    }
    useEffect(() => {
        getDomains()
    }, [])
    useEffect(() => {
        if(domainchoose !== ''  && datechoose !== ''){
            start()
        }
    }, [datechoose , domainchoose])
    return <>
        <div className="row ">
            <div className="col-md-8 mb-3">
                <div className="card">
                        <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                            <h4 className="m-0">Overview</h4>
                            <div className="overviewselect d-flex">
                                <select value={domainchoose}  className="form-select mx-2"  name="choosedomain" id="choosedomain" onChange={(e) => setDomainchoose(e.target.value)} >
                                    {
                                        domains.map((item)=>{
                                            return <option key={item.id} value={item.id}>{item.domain}</option>
                                        }
                                        )
                                    }
                                </select>
                                <select value={datechoose} className="form-select mx-2 " name="choosedate" id="choosedate" onChange={(e)=>setDatechoose(e.target.value)} >
                                    {
                                        chooseable.map((item)=>{
                                            return <option key={item} value={item}>{item} Days</option>
                                        }
                                        )
                                    }
                                </select>
                            </div>
                        </div>
                        <OverViewLine
                            id="uniq_visitors_ovl"
                            data = {deepanalytic}
                        />

                        <OverViewLine
                            id="requests_ovl"
                            data = {deepanalytic}
                        />
                        <OverViewLine
                            id="bytes_ovl"
                            data = {deepanalytic}
                        />
                        <OverViewLine
                            id="cachedRequests_ovl"
                            data = {deepanalytic}
                        />

                    </div>
                </div>
            <div className="col-md-4 mb-3">
               <div className="card">
                <div className="card-body p-3">
                    <CountryChart 
                        data={deepanalytic}
                    />
                </div>
               </div>
            </div>
        </div>
        <div className="row mb-3">
            <div className="col">
                <div className="card">
                    <div className="card-body">
                        <WebTraffic
                            data={deepanalytic}
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-body">
                        <CountryMore
                            data={deepanalytic}
                        />
                    </div>
                </div>
            </div>
        </div>
    </>
}