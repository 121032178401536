import { Link } from "react-router-dom"
export default function Services() {
    return (
        <>
        <div className="about-area  fix area-padding">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="about-content">
                            <div className="about-images wow fadeInLeft" data-wow-delay="0.7s" >
                                <div className="rotmate-image rotateme">
                                    <img src="assets/about/circle.png" alt=""/>
                                </div>
                                <img src="assets/about/ab.png" alt=""/>
                                <div className="video-content">
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                   <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="support-all">
                            <div className="support-services wow fadeInUp" data-wow-delay="0.3s" >
                               <Link className="support-images" to="#">01</Link>
                                <div className="support-content">
                                    <h4><span>Professional Services</span></h4>
                                    <p>Replacing a  maintains the amount of lines. When replacing a selection. help agencies to define. define their new business objectives and then.</p>
                                </div>
                            </div>
                            <div className="support-services wow fadeInUp" data-wow-delay="0.5s" >
                                <Link className="support-images" to="#">02</Link>
                                <div className="support-content">
                                    <h4><span>Safe &amp; Security</span></h4>
                                    <p>Replacing a  maintains the amount of lines. When replacing a selection. help agencies to define. define their new business objectives and then</p>
                                </div>
                            </div>
                            <div className="support-services hidden-sm wow fadeInUp" data-wow-delay="0.7s" >
                                <Link className="support-images" to="#">03</Link>
                                <div className="support-content">
                                    <h4><span>Live Support</span></h4>
                                    <p>Replacing a  maintains the amount of lines. When replacing a selection. help agencies to define. define their new business objectives and then.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            </div>
        </>
    )
}