import CountryFlag from 'react-country-flag';
import countryList from 'country-list';
import { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function CountryChart({data}){
    const [countryLists , setCountryLists] = useState([
        {
            clientCountryName : 'NP',
            bytes : 0,
            requests : 0,
        }
    ]);
    
    const extractTotalData = (data) => {
        const totalData = [];
        data.forEach(item => {
          const countryMap = item.sum.countryMap;
          countryMap.forEach(country => {
            const existingCountry = totalData.find(data => data.clientCountryName === country.clientCountryName);
            if (existingCountry) {
              existingCountry.bytes += country.bytes;
              existingCountry.requests += country.requests;
            } else {
              totalData.push({
                clientCountryName: country.clientCountryName,
                bytes: country.bytes,
                requests: country.requests
              });
            }
          });
        });
        const sortedCountryLists = [...totalData].sort((a, b) => b.requests - a.requests);
        setCountryLists(sortedCountryLists);
    };
      useEffect(() => {
        extractTotalData(data);
      }, [data]);

      return(
        <>  
            <h4>Top 7 Traffic Countries </h4>
            <table className='table table-responsive w-100'>
                <thead>
                    <tr>
                        <th>Country</th>
                        <th>Traffic</th>
                    </tr>
                </thead>
                <tbody>
                    {/* print only 8 */}
                    {countryLists.slice(0,7).map((item) => (
                        // if item.clientCountryName !== 'T1' print else dont
                        (item.clientCountryName !== 'T1') ? ( 
                        <tr key={item.clientCountryName}>
                            <td>
                                <CountryFlag countryCode={item.clientCountryName} svg/>
                                <span className=" "> {countryList.getName(item.clientCountryName)}</span>
                            </td>
                            <td  className='text-end'>
                                <span>{item.requests}</span>
                            </td>
                        </tr>) : 
                        <tr key={item.clientCountryName}>
                            <td>
                                <i class="fa-duotone fa-flag"></i>
                                <span className=" "> Unknown </span>
                            </td>
                            <td className='text-end'>
                                <span>{item.requests}</span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <p className='text-center p-0 m-0'><a href='#traffic_details' >View More</a></p>
        </>
      )
}


