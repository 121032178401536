import { VectorMap } from "@react-jvectormap/core"
import { worldMill } from "@react-jvectormap/world"
export default function WorldMap({countries , mapminmax}){
    return(
        <VectorMap 
            map={worldMill}
            series={
                {
                    regions: [
                        {
                            values: countries,
                            scale: ['#f5e6ff', '#5E32CA'],
                            normalizeFunction: 'polynomial',
                            min : mapminmax.min,
                            max : mapminmax.max
                        }
                    ]
                }
            }
            onRegionTipShow={(e, el, code) => {
                if(countries[code]){
                    el.html(el.html()+' (Total Request - '+countries[code]+')');
                }else{
                    el.html(el.html()+' (Total Request - 0)');
                }
            }}

        />
    )
}