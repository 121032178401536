import axios from "axios";

const Instance = axios.create({
  baseURL: "https://api.gadgetsgeek.au/api/v1/",
});
Instance.interceptors.request.use(
  (response) => {
    const token = localStorage.getItem("token");
    response.headers["Authorization"] = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiYWRtaW4iLCJuYW1lIjoiQWRtaW4gR3V5IiwiZW1haWwiOiJhZG1pbkBhZG1pbi5jb20iLCJwaWN0dXJlIjoibG9nby5wbmciLCJ0b2tlbiI6IiQyYSQxMiRKUU0wSElZeGhyM2hULzZMTjA5am1lUHhSU2l3RldtYS82dzFRaDQ1aC9jUFdWR1VXcUQvRyIsImlhdCI6MTY4ODcwNTgzNX0.K7x33lhIAbbpyh9F9yJMyVKjCGcxSjE8Bw31G-Cmlsc" ;
    return response;
  },
  (error) => {    
    return Promise.reject(error);
  }
);

Instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      console.error("Response error:", error.response.status);
      if(error.response.status === 401){
        localStorage.clear();
        window.location.href = "/login";
      }
    } else if (error.request) {
      console.error("Request error:", error.request);
    } else {
      console.error("Error:", error.message);
    }
    return Promise.reject(error);
  }
);

export default Instance;
