import axios from "axios";
import ProfileNav from "../ProfileNav"
import PageName from "../model/pageName";
import { useState, useEffect, useCallback } from "react";
import { access_id , access_token , bottoken , bot_id } from "../../../Auth/localStorageVariable";

export default function Intents() {
  const [traineddomain, setdomain] = useState([]);
  const [trainedintent, setintent] = useState([]);
  function deletethisitem(e) {
    e.preventDefault();
    const confirmed = window.confirm('Are you sure you want to delete this item?');
    if (confirmed) {
      //  get data-id from target element
      const id = e.target.dataset.id;
      console.log(id);
      e.target.parentElement.parentElement.remove();
    }
  }
  const getIntents = useCallback(() => {
    axios.post(process.env.REACT_APP_API_URL+`getintents`,
            {
                token : bottoken,  access_id,  bot : bot_id
            },
            {
                headers:   { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access_token },
            }
    )
    .then(function (response) {
      setintent(response.data.domains);
      console.log(response.data);
    })
    .catch(function (error) {
      try {
        if (error.response.data.error !== undefined && error.response.data.error === 'Unauthorized') {
          localStorage.clear();
          window.location.href = '/';
        }
      }
      catch (e) {
        console.log('Something Went Wrong')
      }
    });
  } , [])
  const handleDomainChange = (event) => {
    setDomain(event.target.value);
  };
  useEffect(() => {
    getdomain();
    getIntents();
  }, [getIntents] );

  function getdomain() {
    axios.post(process.env.REACT_APP_API_URL+`getdomain`,
            {
                token : bottoken,  access_id,  bot : bot_id
            },
            {
                headers:   { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access_token },
            }
        )
    .then(function (response) {
      setdomain(response.data.domains);
      console.log(response.data);
    })
    .catch(function (error) {
      try {
        if (error.response.data.error !== undefined && error.response.data.error === 'Unauthorized') {
          localStorage.clear();
          window.location.href = '/';
        }
      }
      catch (e) {
        console.log('Something Went Wrong')
      }
    });
  }
  const [intent, setintents] = useState('');
  const [domain, setDomain] = useState('');

  const addintent = (e) => {
    e.preventDefault();
        axios.post(process.env.REACT_APP_API_URL+`addintent`,
            {
                intent , token : bottoken,  access_id,  bot : bot_id , domain_id : domain
            },
            {
                headers:   { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access_token },
            }
        )
          .then((response) => {
            if (response.data.status === 'success') {
              setintents('');
              getIntents();
            }
      })
      .catch((error) => {
        console.log(error);
      });

  }
  return (
      <>   
        <div className="bg-gray-100">
          <ProfileNav />
            <div className="bg-gray-100 main-content position-relative vh-100 ">
              <PageName
                  name='Intents'
              />
              <div className=" card shadow-none mx-3 bg-transparent ">
                <div className="row">
                  <div className="col-12">
                    <div className="card p-4">
                      <form onSubmit={addintent}>
                        <div className="row">
                          <div className="col-6">
                            <label htmlFor="validationCustomUsername">Domain</label>
                            <select className="form-control" id="validationCustomUsername"  placeholder="Domain  eg : agent" aria-describedby="inputGroupPrepend"   required  onChange={handleDomainChange} >
                              <option defaultValue={this} disabled>Select Domain</option>
                                {
                                  traineddomain.map((trained) => {
                                      return (
                                        <option key={`option${trained.id}`} value={trained.id}>{trained.name}</option>
                                      )
                                  })
                                }
                            </select>
                          </div>
                          <div className="col-6">
                            <label htmlFor="validationCustomUsername">Intent</label>
                            <input type="text"  className="form-control" id="validationCustomUsername"  placeholder="Intent  eg : happy" aria-describedby="inputGroupPrepend" required value={intent} onChange={(event) => setintents(event.target.value)} />
                          </div>
                        </div>
                        <button type="submit" className="btn btn-info mt-3">Add</button>
                      </form>
                      <div className="table-responsive p-3">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="p-2">Name</th>
                              <th className="p-2">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              trainedintent.map((trintent) => {
                                return trintent.domain_name && trintent.intent_name && (
                                  <tr key={`intents${trintent.intent_id}`}>
                                    <td className="p-2">{`${trintent.domain_name}.${trintent.intent_name}`}</td>
                                    <td className="p-2">
                                      <i className="cursor-pointer fa-duotone fa-trash text-danger" data-id={trintent.intent_id} onClick={deletethisitem}></i>
                                    </td>
                                  </tr>
                                );
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </>
  );
}
